import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import GenericButton from "../components/shared/GenericButton";
import { logout } from "../store/features/auth/authSlice";

const CreateOrChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Restricted access
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const onLogout = () => {
    dispatch(logout());

    navigate("/");
  };

  return (
    <div className="">
      <div className="flex justify-around font-Tajawal font-bold text-3xl my-40">
        <div className="bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED] text-white w-1/4 h-40 flex items-center justify-center rounded-3xl">
          <Link to="/change-password">تغيير كلمة المرور</Link>
        </div>
        <div className="bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED] text-white w-1/4 h-40 flex items-center justify-center rounded-3xl">
          <Link to="/add-moderator">أضف المشرف</Link>
        </div>
      </div>
      <div className="flex justify-center mb-10">
        <GenericButton
          title="تسجيل خروج"
          btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
          textStyle="px-6"
          isIcon={false}
          onClick={onLogout}
        />
      </div>
    </div>
  );
};

export default CreateOrChange;
