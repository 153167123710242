import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import GenericButton from "../components/shared/GenericButton";

import {
  changePassword,
  reset,
} from "../store/features/auth-moderator/authSlice";
import Loading from "./Loading";

const ChangePasswordModerator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.authModerator
  );

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { currentPassword, newPassword, confirmNewPassword } = formData;

  const [formErrors, setFormErrors] = useState({});

  const validate = (values) => {
    const errors = {};

    if (!values.currentPassword) {
      errors.currentPassword = "كلمة السر الحالية مطلوبة";
    } else if (!values.newPassword) {
      errors.newPassword = "كلمة المرور مطلوبة";
    } else if (values.newPassword.length < 8) {
      errors.newPassword = "يجب أن تكون كلمة المرور أكثر من 8 أحرف";
    } else if (!values.confirmNewPassword) {
      errors.confirmNewPassword = "تأكيد كلمة المرور مطلوبة";
    } else if (values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = "كلمة المرور الجديدة وتأكيدها ليسا متطابقين";
    }

    return errors;
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(
      validate({ currentPassword, newPassword, confirmNewPassword })
    );
    console.log(formErrors);
    console.log(Object.keys(formErrors).length);
    if (Object.keys(formErrors).length === 0) {
      await dispatch(changePassword(formData));
      navigate("/login-moderator");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="h-[70vh] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12">
      <div className="flex justify-center">
        <form className="w-full lg:w-1/3 bg-white p-6 rounded-lg">
          <h1 className="font-Tajawal font-bold text-5xl text-right antialiased pb-10">
            تغيير كلمة المرور
          </h1>

          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              كلمة السر الحالية
            </label>

            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={currentPassword}
              onChange={handleChange}
              required
              className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold"
            />
            {formErrors.currentPassword && (
              <p className="text-red-800 text-right py-5">
                {formErrors.currentPassword}
              </p>
            )}
          </div>

          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              كلمة المرور الجديدة
            </label>

            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              required
              className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold"
            />
            {formErrors.newPassword && (
              <p className="text-red-800 text-right py-5">
                {formErrors.newPassword}
              </p>
            )}
          </div>

          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              تأكيد كلمة المرور
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={handleChange}
              required
              className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold"
            />
            {formErrors.confirmNewPassword && (
              <p className="text-red-800 text-right py-5">
                {formErrors.confirmNewPassword}
              </p>
            )}
          </div>
          <div className="flex justify-center">
            <GenericButton
              title="إعادة تعيين كلمة المرور"
              btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
              textStyle="px-6"
              isIcon={false}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModerator;
