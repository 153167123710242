import React, { useState } from "react";
import { nftApi } from "../../api/api";
import GenericButton from "../../components/shared/GenericButton";
import useModalZustand from "../../store/useModalZustand";

function ReportDetailsModal({ showModal, setShowModal, report, setReport }) {
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);
  // const setSuccessMsg = useModalZustand((state) => state.setSuccessMsg);

  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [reason, setReason] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setErrors({});
  };
  const handleClose = () => {
    setShowModal(false);
    setReport();
    setSelectedOption("");
    setLoading(false);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
    setErrors({});
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (!selectedOption) {
      newErrors.selectedOption = "يجب اختيار خيار واحد على الأقل";
    } else if (selectedOption === "option2" && !reason) {
      newErrors.reason = "يجب إدخال سبب الرفض";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      if (selectedOption === "option1") {
        try {
          const { data, status } = await nftApi.respondToReport(
            report._id,
            true
          );
          if (data && status === 200) {
            setShowModal(false);
            window.location.reload();
            // setSuccessMsg("تم قبول التقرير بنجاح");
            setLoading(false);
          }
        } catch (error) {
          setErrorMsg(error);
        }
      } else {
        try {
          const { data, status } = await nftApi.respondToReport(
            report._id,
            false,
            reason
          );
          if (data && status === 200) {
            setShowModal(false);
            window.location.reload();
            // setSuccessMsg("تم رفض التقرير بنجاح");
            setLoading(false);
          }
        } catch (error) {
          setErrorMsg(error);
        }
      }
    }
  };
  return (
    <div>
      {showModal && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-black opacity-75"></div>
      )}
      {showModal && (
        <div className="fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded-lg w-screen md:w-[600px]  ">
            <div className="w-full absolute right-1 top-1">
              <button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
            <h2 className="w-full text-2xl font-bold flex justify-center">
              تفاصيل التقرير
            </h2>
            <div className="flex flex-col mt-6">
              <div className="flex flex-wrap items-center">
                <span className="text-2xl"> المرسل:</span>
                <span className="text-lg mr-4   "> {report.email}</span>
              </div>
              <div className="flex flex-wrap">
                <span className="text-2xl"> السبب:</span>
                <span className="text-lg mr-4   "> {report.reason}</span>
              </div>
              <div className="w-full flex flex-row justify-center items-center gap-4 mt-6">
                <label className="text-2xl">
                  قبول
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleOptionChange}
                  />
                </label>
                <br />
                <label className="text-2xl">
                  رفض
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === "option2"}
                    onChange={handleOptionChange}
                  />
                </label>
              </div>
              {selectedOption === "option2" && (
                <div className="flex flex-col ">
                  <span className="text-2xl"> سبب الرفض:</span>
                  <textarea
                    type="text"
                    placeholder="سبب الرفض:"
                    maxLength={280}
                    className="border border-gray-300 p-5 "
                    onChange={handleReasonChange}
                  />
                </div>
              )}

              <p className="text-red-500 mt-2 ">{errors.selectedOption}</p>
              <p className="text-red-500 mt-2">{errors.reason}</p>
              <div className="flex flex-row w-full justify-center gap-4 mt-6">
                <GenericButton
                  title="أرسل "
                  btnStyle={`p-4 ${
                    loading
                      ? "bg-slate-400"
                      : "bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                  }`}
                  textStyle="px-6"
                  isIcon={false}
                  onClick={handleSubmit}
                  disabled={loading}
                />
                <button
                  type="button"
                  onClick={handleClose}
                  className="bg-black text-white rounded-full w-1/5 font-bold"
                >
                  إلغاء
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReportDetailsModal;
