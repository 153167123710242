import { create } from "zustand";
import axios from "axios";

const useModeratorStoreZus = create((set) => ({
  data: {},
  editRes: {},
  deleteRes: {},
  loading: false,
  errors: "",
  setModeratorErrors: (msg) => {
    set({ errors: msg });
  },
  fetchAllModerators: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/moderator`
      );
      set(() => ({ data: response.data, loading: false }));
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  editModerator: async (body) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/moderator/edit`,
        body
      );
      set(() => ({ editRes: response, loading: false }));
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  deleteModerator: async (body) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/moderator/delete`,
        body
      );
      set(() => ({ deleteRes: response, loading: false }));
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
}));

export default useModeratorStoreZus;
