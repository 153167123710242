import React from "react";
import GenericButton from "./shared/GenericButton";

function NewLetter() {
  return (
    <section className="block bg-[#6345ed]/[0.07]">
      <div className="relative mx-auto px-4 w-2/3 ">
        <div
          className="visible p-8 bg-gradient-to-r from-[#6345ED] to-fuchsia-500 rounded-3xl -mb-20 md:flex md:flex-wrap md:justify-center md:p-16"
          style={{
            direction: "rtl",
          }}
        >
          <div className="w-full flex flex-col gap-2">
            <h3 className="mb-2 text-white text-4xl	font-bold">
              الأسبوع العربي للبرمجة
            </h3>
            <p className="mb-3 text-lg text-white font-normal">
              شارك برموز الـNFT التي أنشأتها في مسابقة الأسبوع العربي للبرمجة
              وفز بجوائز قيمة
            </p>
            <a
              // className="w-full flex justify-end"
              href="https://arabcodeweek.alecso.org"
              target="_blank"
              rel="noreferrer"
            >
              <GenericButton
                onClick={() => {
                  console.log("i got clicked newsletter");
                }}
                title="اضغط هنا"
                btnStyle="w-[15vw] p-3 px-10 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                textStyle="text-base px-5"
                isIcon={false}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewLetter;
