import React from "react";
import CardFooter from "./CardFooter";

import data from "./data";

function Footer() {
  return (
    <footer className="bg-black flex flex-col justify-center items-center w-screen pt-36 px-0 ">
      <div className="flex flex-wrap-reverse justify-center text-gray-400 p-10 border-b border-b-gray-400">
        {data.map((item) => (
          <CardFooter key={item.id} item={item} />
        ))}
      </div>
      <div className="w-full">
        <div className="max-w-xl p-6 text-center text-gray-400 text-sm m-auto">
          NFT منصة الألكسو للـ
        </div>
      </div>
    </footer>
  );
}

export default Footer;
