import React from "react";

import Icon from "../assets/images/logo/logo-alecso.png";

function Loading() {
  return (
    <div className="w-full h-screen flex justify-center">
      <img
        src={Icon}
        alt="icon"
        className="m-auto animate-pulse animate-load w-32"
      />
    </div>
  );
}

export default Loading;
