import React from "react";
import { useNavigate } from "react-router-dom";
import userImgPlaceHolder from "../../assets/icons/icons8-user-90.svg";

function NFTcard({ item }) {
  const navigate = useNavigate();
  const {
    name,
    createdAt,
    image,
    tokenId,
    marketStatus,
    owner: { name: fullName, photo },
  } = item;
  return (
    <div
      className="bg-[rgba(99,69,237,0.07)] pt-12 pb-9 px-6 border box-border border-gray-300 rounded-3xl w-[350px] cursor-pointer"
      style={{ direction: "rtl" }}
      onClick={() => navigate(`/nft-detail/${tokenId}`, { state: { tokenId } })}
    >
      {/* Product image */}
      <div className="relative mb-6 h-64 ">
        <img
          src={
            image ||
            "https://ipfs.io/ipfs/bafybeiesm3c2e3p33oc32ntmpnden3om43c435g7hykqku5vmzhzxzusvy"
          }
          alt="fleche"
          className="w-full h-full object-fill object-center rounded-3xl "
        />
        {marketStatus?.status_name === "onSale" && (
          <p className="absolute bottom-0 right-6 translate-y-1/2 px-2 py-1 bg-fuchsia-500 rounded-lg text-sm text-white font-semibold">
            للبيع
          </p>
        )}
      </div>
      {/* Product content */}
      <div className="pl-3">
        {/* Title */}
        <h5 className="text-black leading-tight text-lg font-bold mb-4">
          {name}
        </h5>
        {/* Product author */}
        <div className="mb-6 flex items-center">
          <div className="mr-3 shrink-0">
            <img
              src={photo || userImgPlaceHolder}
              alt="avatar"
              className="w-10 h-10 object-cover rounded-full max-w-full align-middle"
            />
          </div>
          <div className="mt-1">
            <span className="text-gray-400 font-medium text-sm px-1">
              {fullName}
            </span>
          </div>
        </div>
        {/* Product price */}
        <div>
          <div className="text-gray-400 mb-1">تاريخ الاصدار</div>
          <div>
            <span className="text-black text-base font-bold">
              {createdAt.split("T")[0]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NFTcard;
