import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../coreUI/Navbar";
import Footer from "../coreUI/Footer/Footer";
import Loading from "../pages/Loading";
import NewLetter from "../components/NewLetter";
import ErrorModal from "../coreUI/ErrorModal";
import SuccessModal from "../coreUI/SuccessModal";

function Layout() {
  return (
    <Suspense fallback={<Loading />}>
      <div className="relative overflow-hidden max-w-full h-full">
        <div>
          <Navbar />
          <Outlet />
          <NewLetter />
          <Footer />
        </div>
        <ErrorModal />
        <SuccessModal />
      </div>
    </Suspense>
  );
}

export default Layout;
