import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useNftStoreZustand from "../../store/useNftStoreZustand";

function GenericButtonBuySell({ type, onClick, btnTitle, icon, ...props }) {
  const loading = useNftStoreZustand((state) => state.loading);

  return (
    <>
      <button
        {...props}
        disabled={loading}
        type={type}
        onClick={onClick}
        className={`w-full flex justify-center p-4 mt-5 rounded-full ${
          loading
            ? "bg-slate-400"
            : "bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
        } hover:transition-all duration-500`}
      >
        <span className={`text-white font-bold mr-2 `}>{btnTitle}</span>
        {loading && (
          <AiOutlineLoading3Quarters className="text-white animate-spin" />
        )}
        {icon && icon}
      </button>
    </>
  );
}

export default GenericButtonBuySell;
