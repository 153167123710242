/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

export const sampleSlice = createSlice({
  name: "sample",
  initialState,
  reducers: {
    INCREMENT: (state) => {
      state.value += 1;
    },
    DECREMENT: (state) => {
      state.value -= 1;
    },
    INCREMENT_BY_AMOUNT: (state, action) => {
      state.value += action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { INCREMENT, DECREMENT, INCREMENT_BY_AMOUNT } =
  sampleSlice.actions;

export default sampleSlice.reducer;
