/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { coinApiOld } from "../api/api";
import GenericButton from "../components/shared/GenericButton";
import useModalZustand from "../store/useModalZustand";
import Web3 from "web3";

function FaucetOld() {
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);
  const setSuccessMsg = useModalZustand((state) => state.setSuccessMsg);

  const [idInput, setIdInput] = useState("");
  const [walletInput, setWalletInput] = useState("");
  const [errors, setErrors] = useState({ idError: false, walletError: false });
  const [loading, setLoading] = useState(false);
  const transferToken = async () => {
    setLoading(true);
    try {
      await coinApiOld.transferAlecsoCoin(walletInput, idInput);
      setSuccessMsg(
        "تمت إضافة عملة الالكسو بنجاح إلى محفظة Metamask الخاصة بك"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log({ error });
      if (
        error.message === "Student with wallet already exists with other id"
      ) {
        setErrorMsg(
          "هذا المستخدم أو هذه المحفظة الرقمية تحصل(ت) على عملة رقمية من قبل بالتالي لم يعد بالامكان إضافة العملة مرّة أخرى"
        );
      } else if (error.message === "Student blocked from getting more coins") {
        setErrorMsg(
          "هذا المستخدم أو هذه المحفظة الرقمية تحصل(ت) على عملة رقمية من قبل بالتالي لم يعد بالامكان إضافة العملة مرّة أخرى"
        );
      } else {
        return setErrorMsg(error);
      }
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    const isAddress = Web3.utils.isAddress(walletInput);
    if (!idInput || !walletInput || !isAddress) {
      if (!walletInput || !isAddress) {
        setErrors({ ...errors, walletError: true });
      }
      if (!idInput) {
        setErrors({ ...errors, idError: true });
      }
    } else {
      setErrors({ ...errors, walletError: false, idError: false });
      transferToken();
      setIdInput("");
      setWalletInput("");
    }
  }

  return (
    <div className="h-[60vh] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12">
      <div className="flex justify-center">
        <form
          className="w-full lg:w-1/3 bg-white p-6 rounded-lg  "
          onSubmit={handleSubmit}
        >
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل المعرف
            </label>
            <input
              placeholder="... المعرف"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold ${
                errors.idError ? "border-red-600" : ""
              }`}
              value={idInput}
              onChange={(e) => {
                setIdInput(e.target.value);
                setErrors({ ...errors, idError: false });
              }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل محفظتك
            </label>
            <input
              placeholder="... المحفظه"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold ${
                errors.walletError ? "border-red-600" : ""
              }`}
              value={walletInput}
              onChange={(e) => {
                setWalletInput(e.target.value);
                setErrors({ ...errors, walletError: false });
              }}
            />
          </div>
          <div className="flex justify-center">
            <GenericButton
              title="أرسل لي عملة"
              btnStyle={`p-4 ${
                loading
                  ? "bg-slate-400"
                  : "bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
              }`}
              textStyle="px-6"
              isIcon={false}
              onClick={handleSubmit}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FaucetOld;
