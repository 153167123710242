import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-locize-backend";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
// .use(Backend)
// .init({
//   debug: true,
//   fallbackLng: "en",
//   saveMissing: true,
//   backend: {
//     projectId: "f6b96acc-83d3-4fef-8c66-51ba870291dd",
//     apiKey: "42fe8d1b-3d47-4a6a-a4db-c446fafc437a",
//   },
// });

export default i18n;
