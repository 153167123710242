/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allNfts: [],
  myNfts: [],
  reportedNfts: [],
};

export const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    GET_ALL_NFTS: (state, action) => {
      state.allNfts = action.payload;
    },
    GET_MY_NFTS: (state, action) => {
      state.myNfts = action.payload;
    },
    GET_REPORTED_NFTS: (state, action) => {
      state.reportedNfts = action.payload;
    },
  },
});

export const { GET_ALL_NFTS, GET_MY_NFTS, GET_REPORTED_NFTS } =
  nftSlice.actions;

export default nftSlice.reducer;
