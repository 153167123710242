import * as React from "react";

const DeleteIcon = ({ onClick }) => {
  return (
    <div className="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="mt-4 text-red-800 cursor-pointer"
        onClick={onClick}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill="currentColor"
          d="M16.243 8.757a.999.999 0 0 0-1.414 0L12 10.586l-2.829-2.83a.999.999 0 1 0-1.414 1.414L10.586 12l-2.83 2.829a.999.999 0 1 0 1.414 1.414L12 13.414l2.829 2.829a.997.997 0 0 0 .707.293.999.999 0 0 0 .707-1.707L13.414 12l2.829-2.829a.999.999 0 0 0 0-1.414z"
        />
      </svg>
    </div>
  );
};

export default DeleteIcon;
