/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Web3 from "web3";

import FileInput from "../components/Form/FileInput";
import TextInput from "../components/Form/TextInput";
import TextArea from "../components/Form/TextArea";
import GenericButton from "../components/shared/GenericButton";
import GenericButtonSubmit from "../components/shared/GenericButtonSubmit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useNftStoreZustand from "../store/useNftStoreZustand";
import useModalZustand from "../store/useModalZustand";
import BreadCrumbs from "../components/shared/BreadCrambs";
import useStudentStoreZus from "../store/useStudentStoreZus";
import Dropdown from "../components/Form/Dropdown";
import categories from "../constants/categories";
function AddNFT() {
  const { createNft, createNFtRes, loading, fetchErrors } = useNftStoreZustand(
    (state) => ({
      createNFtRes: state.createNFtRes,
      fetch: state.fetch,
      loading: state.loading,
      fetchErrors: state.errors,
      createNft: state.createNft,
    })
  );
  const loginStudentRes = useStudentStoreZus((state) => state.loginStudentRes);
  const setSuccessMsg = useModalZustand((state) => state.setSuccessMsg);

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    console.log({ loginStudentRes });
    setHidden(Object.keys(loginStudentRes).length === 0);
  }, [loginStudentRes]);

  useEffect(() => {
    console.log({ createNFtRes });
  }, [createNFtRes]);

  const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];

  const nftSchema = yup.object({
    // name: yup.string().required(t("validation.name","اسم الرمز مطلوب")),
    // .required(t("validation.name.required", "NFT name is required")),

    // .required(t("validation.description.required", "description required")),
    // price: yup
    //   .number()
    //   .required(t("validation.price.required", "Price is required"))
    //   .positive(
    //     t("validation.price.positive", "Price must be a positive number")
    //   ),
    // quantity: yup
    //   .number()
    //   .required(t("validation.quantity.required", "Quantity is required"))
    //   .positive(
    //     t("validation.quantity.positive", "Quantity must be a positive number")
    //   ),
    // royalty: yup.string().required(t("validation.royalty", "royalty required")),

    name: yup.string().required("اسم الرمز مطلوب"),
    description: yup.string().required("الوصف مطلوب"),
    projectLink: yup.string().required("رابط المشروع مطلوب"),

    // filePDF: yup
    //   .mixed()
    //   .test(
    //     "fileExists",
    //     // t("validation.filePDF.fileExists", "File PDF is required"),
    //     "ملف PDF مطلوب",
    //     (value) => {
    //       console.log({ filePDF: value });
    //       return value && value[0];
    //     }
    //   )
    //   .test(
    //     "fileSize",
    //     // t("validation.filePDF.fileSize", "File size must be less than 5MB"),
    //     "يجب أن يكون حجم الملف أقل من 5 ميغا بايت",
    //     (value) => value && value[0]?.size <= 5 * 1024 * 1024
    //   )
    //   .test(
    //     "fileType",
    //     // t("validation.filePDF.fileType", "File must be a PDF"),
    //     "يجب أن يكون الملف بتنسيق PDF",
    //     (value) => value && value[0]?.type === "application/pdf"
    //   ),
    thumbnail: yup
      .mixed()
      .test(
        "fileExists",
        // t("validation.thumbnail.fileExists", "Image thumbnail is required"),
        "الصورة المصغرة مطلوبة",
        (value) => {
          console.log({ thumbnail: value });
          return value && value[0];
        }
      )
      .test(
        "fileSize",
        // t("validation.thumbnail.fileSize", "Image size must be less than 5MB"),
        "يجب أن يكون حجم الصورة أقل من 5 ميغا بايت",
        (value) => value && value[0]?.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        // t("validation.thumbnail.fileType", "File must be an image PNG or JPEG"),
        "يجب أن يكون الملف صورة بتنسيق PNG أو JPEG",
        (value) => value && ALLOWED_IMAGE_TYPES.includes(value[0]?.type)
      ),
  });
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(nftSchema),
  });

  const watchFilePDF = watch("filePDF"); // you can supply default value as second argument
  const watchThumbnail = watch("thumbnail"); // you can supply default value as second argument

  const onSubmit = async (values) => {
    // Use the data to create the
    console.log({ values });
    let formData = new FormData();

    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("projectLink", values.projectLink);
    formData.append("category", values.category);

    formData.append("owner", JSON.stringify(loginStudentRes));

    formData.append("image", values.thumbnail[0].name);
    // formData.append("file", values.filePDF[0].name);

    formData.append("thumbnail", values.thumbnail[0]);
    // formData.append("filePDF", values.filePDF[0]);

    await createNft(formData);
    setSuccessMsg(
      "تمّ تسجيل بيانات رمز الـNFT بنجاح."
    );

    reset();
  };

  return (
    <>
      <BreadCrumbs />
      <div className="w-screen p-8 flex flex-col justify-start items-center bg-[#6345ed]/[0.07] mb-24">
        <div className="w-[90vw] md:w-[750px] lg:w-[970px] xl:w-[1200px] flex flex-row justify-between bg-white rounded-lg shadow-xl shadow-[#6345ed]/[0.15] text-right">
          <div className="w-full h-full py-12 px-8 lg:py-16 lg:px-20 flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <h1 className="font-Tajawal font-bold text-5xl antialiased">
                إضافة رمز NFT
              </h1>
              <div>
                <p className="font-Tajawal font-normal text-lg text-[#616161] antialiased ">
                  يمكنك رفع أي نوع من الملفات
                </p>
                <p className="font-Tajawal font-normal text-lg text-[#616161] antialiased ">
                  إذا كان مشروعك ليس صورة فحينها يجب اختيار صورة ترمز لمشروعك
                  لإبرازها في المنصة
                </p>
              </div>
            </div>

            <form
              className=""
              onSubmit={handleSubmit(onSubmit)}
              method="POST"
              enctype="multipart/form-data"
            >
              <div className="flex flex-col gap-4">
                {/* <FileInput
                  type="file"
                  register={register}
                  errors={errors}
                  title="اختر ملف"
                  name="filePDF"
                  watch={watchFilePDF}
                  disabled={hidden}
                /> */}
                <FileInput
                  type="file"
                  register={register}
                  errors={errors}
                  title="اختر الصورة البارزة لمشروعك على المنصة"
                  name="thumbnail"
                  watch={watchThumbnail}
                  disabled={hidden}
                />
                <TextInput
                  type="text"
                  register={register}
                  errors={errors}
                  title="NFT اسم رمز الـ"
                  name="name"
                  disabled={hidden}
                />
                <TextInput
                  type="text"
                  register={register}
                  errors={errors}
                  title="رابط المشروع"
                  name="projectLink"
                  disabled={hidden}
                />

                {/* <div className="w-full flex flex-col md:flex md:flex-row gap-4">
                  <TextInput
                    type="number"
                    register={register}
                    errors={errors}
                    title="السعر المقترح"
                    name="price"
                    disabled={hidden}
                  />
                </div> */}

                <Dropdown
                  name="category"
                  register={register}
                  options={categories}
                />
                <TextArea
                  type="textarea"
                  register={register}
                  errors={errors}
                  title="وصف مختصر للرمز"
                  name="description"
                  disabled={hidden}
                />
              </div>

              <GenericButtonSubmit
                type="submit"
                hidden={hidden}
                disabled={loading}
              />
            </form>
          </div>
          <img
            className="w-96 max-w-md h-full object-cover hidden md:block"
            src={require("../assets/images/background/img-create-item.jpg")}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default AddNFT;

/*
return (
    <>
      <div className="w-screen h-screen flex flex-col justify-start items-center bg-[#e5e5e5]">
        <p>{t("fatherKey", "father")}</p>

        <div className="w-[50vw] h-[50vh] flex flex-row justify-between bg-white rounded-lg shadow-xl shadow-[#6345ed]/[0.15] text-right">
          <div>
          <div className="">
            <h1 className="text-right">إضافة رمز NFT</h1>
            <p>يمكنك رفع أي نوع من الملفات</p>
            <p>إذا كان مشروعك ليس صورة فحينها يجب اختيار صورة ترمز لمشروعك لإبرازها في المنصة</p>
          </div>

            <form className="py-16 px-20" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col">
                <label htmlFor="file">
                  {t("label.file", "Choose file")}

                  <input type="file" name="file" {...register("file")} />
                  {errors.file && <span>{errors.file.message}</span>}
                </label>
                <label htmlFor="image">
                  {t("label.image", "Choose your NFT picture")}
                  <input
                    className="w-full h-16 appearance-none px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md"
                    type="file"
                    name="image"
                    {...register("image")}
                  />
                  {errors.image && <span>{errors.image.message}</span>}
                </label>
                <FileInput
                  type="file"
                  register={register}
                  errors={errors}
                  title="اختر ملف"
                  name="myInput"
                />
                <div className="flex flex-row">
                  <label htmlFor="price">
                    {t("label.price", "The Price")}
                    <input type="number" name="price" {...register("price")} />
                    {errors.price && <span>{errors.price.message}</span>}
                  </label>
                  <label htmlFor="name">
                    {t("label.name", "The NFT Name")}
                    <input
                      className="w-full h-16 appearance-none px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md"
                      type="text"
                      name="name"
                      {...register("name")}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </label>
                </div>
                <div className="flex flex-row">
                  <label htmlFor="size">
                    {t("label.size", "The size")}
                    <input type="number" name="size" {...register("size")} />
                    {errors.size && <span>{errors.size.message}</span>}
                  </label>
                  <label htmlFor="royalty">
                    {t("label.royalty", "Royalty Value")}
                    <input
                      type="text"
                      name="royalty"
                      {...register("royalty")}
                    />
                    {errors.royalty && <span>{errors.royalty.message}</span>}
                  </label>
                </div>
                <label htmlFor="nbCopies">
                  {t("label.nbCopies", "Number of copies")}
                  <input
                    type="textarea"
                    name="nbCopies"
                    {...register("nbCopies")}
                  />
                  {errors.description && (
                    <span>{errors.description.message}</span>
                  )}
                </label>
                <label htmlFor="description">
                  {t("label.description", "Description")}
                  <input
                    type="textarea"
                    name="description"
                    {...register("description")}
                  />
                  {errors.description && (
                    <span>{errors.description.message}</span>
                  )}
                </label>
              </div>

              <button type="submit" value={t("button", "Create NFT")} />
            </form>
          </div>
          <img
            className="w-96 h-full object-cover "
            src={require("../assets/images/background/img-create-item.jpg")}
            alt=""
          />
        </div>
      </div>
    </>
  );
*/
