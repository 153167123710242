import DashboardIcon from "../assets/icons/dashboard.svg";
import UserIcon from "../assets/icons/user.svg";
// import { MdAddModerator } from "react-icons/md";

const sidebar_menu = [
  {
    id: 1,
    icon: DashboardIcon,
    path: "/dashboard-admin",
    title: "لوحة القيادة",
  },
  {
    id: 2,
    icon: DashboardIcon,
    path: "/dashboard-admin/moderators",
    title: "قائمة المشرفين",
  },
  {
    id: 3,
    icon: UserIcon,
    path: "/dashboard-admin/change-password",
    title: "حسابي",
  },
];

export default sidebar_menu;
