/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as yup from "yup";

import GenericButton from "../components/shared/GenericButton";
import Loading from "./Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useModeratorStoreZus from "../store/useModeratorStoreZus";
import useModalZustand from "../store/useModalZustand";

const EditModerator = () => {
  const navigate = useNavigate();
  const { moderatorId } = useParams();
  const { state } = useLocation();
  const setSuccessMsg = useModalZustand((state) => state.setSuccessMsg);

  const { editModerator, editRes, loading } = useModeratorStoreZus((state) => ({
    editModerator: state.editModerator,
    editRes: state.editRes,
    loading: state.loading,
  }));

  const moderatorSchema = yup.object({
    firstName: yup.string().required("الاسم الأول مطلوب"),
    lastName: yup.string().required("اسم العائلة مطلوب"),
    university: yup.string().required("اسم الجامعة مطلوب"),
    email: yup.string().required("البريد الإلكتروني مطلوب"),
  });

  const defaultValues = {
    ...state,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(moderatorSchema),
  });

  const onSubmit = async (data) => {
    await editModerator(data);
    navigate("../moderators");
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="h-[80vh] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12">
      <div className="flex justify-center">
        <form
          className="w-full lg:w-1/3 bg-white p-6 rounded-lg"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className="font-Tajawal font-bold text-5xl text-right antialiased pb-10">
            تعديل المشرف
          </h1>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل الاسم الأول
            </label>
            <input
              {...register("firstName")}
              placeholder="... الاسم الأول"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold 
               ${errors.firstName ? "border-red-600" : ""}`}
            />
            {errors.firstName && (
              <span className="text-red-600 italic text-sm mt-1">
                {errors.firstName.message}
              </span>
            )}
          </div>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل اسم العائلة
            </label>
            <input
              {...register("lastName")}
              placeholder="... اسم العائلة"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold 
               ${errors.lastName ? "border-red-600" : ""}`}
            />
            {errors.lastName && (
              <span className="text-red-600 italic text-sm mt-1">
                {errors.lastName.message}
              </span>
            )}
          </div>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل اسم الجامعة
            </label>
            <input
              name="university"
              {...register("university")}
              placeholder="... اسم الجامعة"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold 
               ${errors.university ? "border-red-600" : ""}`}
            />
            {errors.university && (
              <span className="text-red-600 italic text-sm mt-1">
                {errors.university.message}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل البريد الإلكتروني
            </label>
            <input
              name="email"
              {...register("email")}
              placeholder="... البريد الإلكتروني"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold ${
                errors.email ? "border-red-600" : ""
              }`}
            />
            {errors.email && (
              <span className="text-red-600 italic text-sm mt-1">
                {errors.email.message}
              </span>
            )}
          </div>
          <button type="submit" className="flex justify-between">
            <GenericButton
              title="تعديل المشرف"
              btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
              textStyle="px-6"
              isIcon={false}
              disabled={loading}
            />
          </button>
        </form>
      </div>
    </div>
  );
};
export default EditModerator;
