import React, { useState, useEffect } from "react";
import { FaTimesCircle } from "react-icons/fa";
import useModalZustand from "../store/useModalZustand";
import useNftStoreZustand from "../store/useNftStoreZustand";
import useStudentStoreZus from "../store/useStudentStoreZus";
import useModeratorStoreZus from "../store/useModeratorStoreZus";

const ErrorModal = () => {
  const { errorMsg, setErrorMsg } = useModalZustand((state) => ({
    errorMsg: state.errorMsg,
    setErrorMsg: state.setErrorMsg,
  }));
  const { errorsStudent, setStudentErrors } = useStudentStoreZus((state) => ({
    errorsStudent: state.errors,
    setStudentErrors: state.setStudentErrors,
  }));
  const { errorsModerator, setModeratorErrors } = useModeratorStoreZus(
    (state) => ({
      errorsModerator: state.errors,
      setModeratorErrors: state.setModeratorErrors,
    })
  );
  const errorsNFT = useNftStoreZustand((state) => state.errors);
  // const navigate = useNavigate();
  // const [seeInfo, setSeeInfo] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [myErrorMsg, setMyErrorMsg] = useState("");

  useEffect(() => {
    if (errorMsg !== "" && errorMsg !== undefined) {
      setMyErrorMsg(errorMsg);
      setIsOpen(true);
    }
    if (errorsNFT !== "" && errorsNFT !== undefined) {
      setMyErrorMsg(errorsNFT);
      setIsOpen(true);
    }
    if (errorsStudent !== "" && errorsStudent !== undefined) {
      setMyErrorMsg(errorsStudent);
      setIsOpen(true);
    }
    if (errorsModerator !== "" && errorsModerator !== undefined) {
      setMyErrorMsg(errorsModerator);
      setIsOpen(true);
    }
  }, [errorMsg, errorsNFT, errorsStudent, errorsModerator]);

  return (
    <div
      className={`animate-load_Modal fixed bottom-0 right-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center`}
      style={{
        display: isOpen ? "flex" : "none",
        direction: "rtl",
      }}
    >
      <div
        className={`bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          className={`bg-red-400 text-white font-bold rounded-t px-4 py-2 flex gap-2 items-center`}
        >
          خطأ
          <FaTimesCircle className="w-4 h-4" />
        </div>
        <div className={`border-t border-red-400 px-4 py-5 sm:p-6`}>
          {/* <div className="flex gap-2">
            <h3
              id="modal-headline"
              className={`text-lg leading-6 font-medium text-gray-900`}
            >
              خطأ عند التواصل مع الخادم
            </h3>
            <button
              className="w-5 h-5 border border-slate-600 rounded-full bg-slate-300 text-slate-600"
              onClick={() => setSeeInfo((prev) => !prev)}
            >
              i
            </button>
          </div>
          {seeInfo && (
            <div
              className={`bg-slate-200 mt-2 text-sm leading-5 text-gray-500 overflow-auto`}
            >
              {myErrorMsg}
            </div>
          )} */}
          <div className="flex gap-2">
            {myErrorMsg ===
            ' الرجاء الذهاب إلى صفحة "تحصل على عملة الألكسو" (اضغط هنا) قبل تفعيل ربط المحفظة الرقمية' ? (
              <h3
                id="modal-headline"
                className={`text-lg leading-6 font-medium text-gray-900`}
              >
                {'الرجاء الذهاب إلى صفحة "تحصل على عملة الألكسو"'}
                <br />
                <a
                  href={`${process.env.REACT_APP_CLIENT_IP}/faucet`}
                  className="text-blue-600 italic  "
                >
                  (اضغط هنا){" "}
                </a>
                قبل تفعيل ربط المحفظة الرقمية
              </h3>
            ) : (
              <h3
                id="modal-headline"
                className={`text-lg leading-6 font-medium text-gray-900`}
              >
                {myErrorMsg}
              </h3>
            )}
          </div>
        </div>
        <div
          className={`bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:items-center`}
        >
          <span
            className={`flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto`}
          >
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
              onClick={() => {
                setIsOpen(false);
                console.log("i got closed");
                setErrorMsg("");
                setStudentErrors("");
                setModeratorErrors("");
                setMyErrorMsg("");
              }}
            >
              إغلاق
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
