import DashboardIcon from "../assets/icons/dashboard.svg";
// import ShippingIcon from "../assets/icons/shipping.svg";
// import ProductIcon from "../assets/icons/product.svg";
import UserIcon from "../assets/icons/user.svg";
import nftHand from "../assets/icons/nft-hand-13049.svg";
import nftReport from "../assets/icons/chart-1482.svg";

const sidebar_menu = [
  {
    id: 1,
    icon: DashboardIcon,
    path: "/dashboard",
    title: "لوحة القيادة",
  },
  {
    id: 2,
    icon: nftHand,
    path: "/dashboard/nftsModerator",
    title: "قائمة الرموز",
  },
  {
    id: 3,
    icon: nftHand,
    path: "/dashboard/requests",
    title: "طلبات الرموز",
  },
  {
    id: 4,
    icon: nftReport,
    path: "/dashboard/reports",
    title: "تقارير الرموز",
  },
  {
    id: 5,
    icon: UserIcon,
    path: "/dashboard/profile",
    title: "حسابي",
  },
];

export default sidebar_menu;
