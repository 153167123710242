import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useNftStoreZustand from "../../store/useNftStoreZustand";

function GenericButtonSubmit({ type, onClick, hidden, ...props }) {
  const loading = useNftStoreZustand((state) => state.loading);

  return (
    <>
      {hidden ? (
        <p
          className="text-lg text-red-500 font-Tajawal font-thin mt-5"
          style={{ direction: "rtl" }}
        >
          عليك أن تتصل بعنوان Metamask صحيح وذلك لتتمكن من الاستفادة من خدمات
          التطبيق بشكل كامل.
        </p>
      ) : (
        <button
          {...props}
          disabled={loading}
          type={type}
          onClick={onClick}
          className={`w-full flex justify-center p-4 mt-5 rounded-full ${
            loading
              ? "bg-slate-400"
              : "bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
          } hover:transition-all duration-500 `}
        >
          <span className={`text-white font-bold mr-2 `}>{"أضف الرّمز"}</span>
          {loading ? (
            <AiOutlineLoading3Quarters className="text-white animate-spin" />
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} className="text-white" />
          )}
        </button>
      )}
    </>
  );
}

export default GenericButtonSubmit;
