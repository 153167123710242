import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";

import BreadCrumbs from "../components/shared/BreadCrambs";
import NFTcard from "../components/shared/NFTcard";
import { nftApi } from "../api/api";
import { GET_MY_NFTS } from "../store/features/nftSlice/nftSlice";
import Loading from "./Loading";
import useStudentStoreZus from "../store/useStudentStoreZus";
import useModalZustand from "../store/useModalZustand";

function StudentNFTs() {
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);

  const loginStudentRes = useStudentStoreZus((state) => state.loginStudentRes);
  const [myNfts, setMyNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchMyNfts = async () => {
      setIsLoading(true);
      try {
        const { data, status } = await nftApi.getMyNfts(
          loginStudentRes?.wallet_address
        );
        if (data && status === 200) setIsLoading(false);
        dispatch(GET_MY_NFTS(data));
        setMyNfts(data);
      } catch (error) {
        setIsLoading(false);
        console.log({ error });
        return setErrorMsg(error);
      }
    };
    if (Object.keys(loginStudentRes).length !== 0) {
      fetchMyNfts();
    }
  }, [loginStudentRes, setErrorMsg]);

  return (
    <div>
      <BreadCrumbs />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-row flex-wrap gap-8 m-20">
          {myNfts.map((nft) => {
            return <NFTcard key={v4()} item={nft} />;
          })}
        </div>
      )}
    </div>
  );
}

export default StudentNFTs;
