import React from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
const Dropdown = ({ register, name, options }) => {
  return (
    <div className="w-full h-16 flex flex-row justify-between items-center relative">
      <span className="ml-8">
        <BsFillCaretDownFill color="#6345ED" />
      </span>
      <select
        {...register(name)}
        name={name}
        className="w-full h-16 appearance-none cursor-pointer absolute px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right text-black font-bold"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
