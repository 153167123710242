import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";

function FileInput({ type, register, errors, name, title, watch, disabled }) {
  return (
    <div className="flex flex-col">
      <label
        className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md "
        htmlFor={name}
      >
        {type === "file" && (
          <span>
            <FaCloudUploadAlt color="#6345ED" />
          </span>
        )}
        <input
          className="w-full h-16 absolute left-0 top-0 cursor-pointer opacity-0"
          type={type}
          name={name}
          {...register(name)}
          disabled={disabled}
        />
        <div className="flex flex-col">
          <span className="text-right font-bold">{title}</span>
          {watch && (
            <span className="text-right font-thin text-gray-500 mt-1">
              {watch[0]?.name}
            </span>
          )}
        </div>
      </label>
      {errors[name] && (
        <span className="text-red-500 font-thin italic">
          {errors[name].message}
        </span>
      )}
    </div>
  );
}

export default FileInput;
