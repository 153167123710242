import { create } from "zustand";
import axios from "axios";

const useStudentStoreZus = create((set) => ({
  loginStudentRes: {},
  loading: false,
  errors: "",
  setStudentErrors: (msg) => {
    set({ errors: msg });
  },
  fetch: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/auth`
      );
      set(() => ({ loginStudentRes: response.data, loading: false }));
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  loginStudent: async (body) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/auth/loginStudent`,
        body
      );
      set(() => ({
        loginStudentRes: response.data,
        errors: "",
        loading: false,
      }));
      return response.data;
    } catch (err) {
      console.log({ myERR: err });
      let myERR = JSON.stringify(err.response.data);
      if (err.response.data === "No Student found") {
        myERR =
          // "لا يوجد حساب مسجل. للتسجيل بمعرف طالبك، يرجى الحصول على توكين ألكسو أولي من خلال  صفحة: المشاركة عبر الأسبوع العربي للبرمجة";
          ' الرجاء الذهاب إلى صفحة " تسجيل مستخدم جديد" (اضغط هنا) قبل تفعيل ربط المحفظة الرقمية';
      }
      if (
        document.location.pathname !== "/faucet" ||
        document.location.pathname !== "/faucet-old"
      ) {
        set(() => ({
          loginStudentRes: {},
          errors: myERR,
          loading: false,
        }));
      }

      return myERR;
    }
  },
  logoutStudent: () => set({ loginStudentRes: {} }),
}));

export default useStudentStoreZus;
