import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import useModalZustand from "../store/useModalZustand";

const SuccessModal = () => {
  const { successMsg, setSuccessMsg } = useModalZustand((state) => ({
    successMsg: state.successMsg,
    setSuccessMsg: state.setSuccessMsg,
  }));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (successMsg !== "") {
      setIsOpen(true);
    }
  }, [successMsg]);

  return (
    <div
      className={`animate-load_Modal fixed bottom-0 right-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center`}
      style={{
        display: isOpen ? "flex" : "none",
        direction: "rtl",
      }}
    >
      <div
        className={`bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          className={`bg-green-400 text-white font-bold rounded-t px-4 py-2 flex gap-2 items-center`}
        >
          <FaCheckCircle className="w-4 h-4" />
          نجاح
        </div>
        <div className={`border-t border-green-400 px-4 py-5 sm:p-6`}>
          <div className="flex">
            <h3
              id="modal-headline"
              className={`text-lg leading-6 font-medium text-gray-900`}
            >
              {successMsg}
            </h3>
          </div>
          <div className={`mt-2 text-sm leading-5 text-gray-500`}></div>
        </div>
        <div
          className={`bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:items-center`}
        >
          <span
            className={`flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto`}
          >
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
              onClick={() => {
                setIsOpen(false);
                console.log("i got closed");
                if (
                  successMsg ===
                  "تمت إضافة عملة الالكسو بنجاح إلى محفظة Metamask الخاصة بك"
                ) {
                  setSuccessMsg("");
                  return window.location.reload();
                }
                setSuccessMsg("");
              }}
            >
              تم
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
