const categories = [
  "تطبيقات جوالة",
  "ذكاء اصطناعي",
  "قصص",
  "ألعاب تعليمية",
  "صور",
  "قصائد",
  "الخط لعربي",
];

export default categories;
