import React from "react";

function TextInput({ type, register, errors, name, title, disabled }) {
  return (
    <div className="w-full flex flex-col">
      <input
        className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-black placeholder:font-bold"
        placeholder={title}
        type={type}
        name={name}
        {...register(name)}
        disabled={disabled}
      />
      {errors[name] && (
        <span className="text-red-500 font-thin italic">
          {errors[name].message}
        </span>
      )}
    </div>
  );
}

export default TextInput;
