import React from "react";

const ErrorRoute = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-8 rounded-lg shadow-lg text-white text-center">
        <h1 className="text-4xl mb-4">! حدث خطأ</h1>
        <p className="text-lg">لا يمكن العثور على الصفحة المطلوبة.</p>
        <button
          onClick={() => window.history.back()}
          className="bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white px-4 py-2 rounded mt-6"
        >
          العودة إلى الصفحة السابقة
        </button>
      </div>
    </div>
  );
};

export default ErrorRoute;
