import { configureStore } from "@reduxjs/toolkit";
import sampleReducer from "./features/sampleSlice/sampleSlice";
import nftReducer from "./features/nftSlice/nftSlice";
import authReducer from "./features/auth/authSlice";
import authReducerModerator from "./features/auth-moderator/authSlice";
import moderatorReducer from "./features/moderator/moderatorSlice";

const store = configureStore({
  reducer: {
    sample: sampleReducer,
    nft: nftReducer,
    auth: authReducer,
    authModerator: authReducerModerator,
    moderator: moderatorReducer,
  },
});

export default store;
