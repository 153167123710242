import React from "react";

const ErrorFallback = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-8 rounded-lg shadow-lg text-white text-center">
        <h1 className="text-4xl mb-4">! حدث خطأ</h1>
        <p className="text-lg">حدث خطأ غير متوقع.</p>
        <p className="text-lg">
          الرجاء إعادة تحميل الصفحة أو المحاولة مرة أخرى لاحقًا.
        </p>
      </div>
    </div>
  );
};

export default ErrorFallback;
