import axios from "axios";

// eslint-disable-next-line no-unused-vars
const userApiClientBase = () =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_IP,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

const userApiClientBaseFormData = () =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_IP,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });

export const nftApi = {
  async getAllNfts(limit) {
    try {
      const res = await userApiClientBase().get(`/nft?limit=${limit}`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getMyNfts(wallet) {
    try {
      const res = await userApiClientBase().get(`/nft/my-nfts/${wallet}`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async reportNft(nftId, data) {
    try {
      const res = await userApiClientBase().post(`/nft/report/${nftId}`, data);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getNftsByCreator(creator) {
    try {
      const res = await userApiClientBase().get(`/nft/creator/${creator}`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getReportedNfts() {
    try {
      const res = await userApiClientBase().get(`/nft/reported/nfts`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getNftsReports(nftId) {
    try {
      const res = await userApiClientBase().get(`/nft/reports/${nftId}`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async respondToReport(reportId, answer, reason) {
    try {
      const res = await userApiClientBase().post(
        `/nft/report-respond/${reportId}`,
        {
          answer,
          reason,
        }
      );
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

export const coinApi = {
  async transferAlecsoCoin(formData) {
    try {
      const res = await userApiClientBaseFormData().post(
        "/coin/register",
        formData
      );
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

export const coinApiOld = {
  async transferAlecsoCoin(wallet, id) {
    try {
      const res = await userApiClientBase().post("/coin", { wallet, id });
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

export const studentApi = {
  async getAllStudents() {
    try {
      const res = await userApiClientBase().get(`/student`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBestContributors() {
    try {
      const res = await userApiClientBase().get(`/student/top`);
      return res;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};
