const arabicCountryNames = [
  "مصر",
  "الجزائر",
  "السعودية",
  "العراق",
  "المغرب",
  "السودان",
  "اليمن",
  "سوريا",
  "تونس",
  "الإمارات العربية المتحدة",
  "الأردن",
  "ليبيا",
  "لبنان",
  "الكويت",
  "المملكة المغربية",
  "عمان",
  "قطر",
  "البحرين",
  "جيبوتي",
  "موريتانيا",
  "جزر القمر",
  "فلسطين",
];

export default arabicCountryNames;
