import React, { useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";
import { nftApi } from "../../api/api";
import DropDown from "../shared/DropDown";
import GenericButton from "../shared/GenericButton";
// import CardsList from "./CardsList";
import { v4 } from "uuid";
import NFTcard from "../../components/shared/NFTcard";
import { GET_MY_NFTS } from "../../store/features/nftSlice/nftSlice";
import Loading from "../../pages/Loading";
import useModalZustand from "../../store/useModalZustand";
import DeleteIcon from "../Icons/DeleteIcon";

function CategorySelect() {
  const categoryRef = useRef(null);
  const sortTypeRef = useRef(null);
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);

  const [myNfts, setMyNfts] = useState([]);
  const [myNftsCount, setMyNftsCount] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("");
  const [category, setCategory] = useState("");
  // const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getNfts = async () => {
      setIsLoading(true);
      try {
        const { data, status } = await nftApi.getAllNfts(0);
        if (data && status === 200) setIsLoading(false);
        dispatch(GET_MY_NFTS(data?.nfts));
        setMyNfts(data?.nfts);
        setMyNftsCount(data?.nftsCount);
      } catch (error) {
        console.log({ error });
        return setErrorMsg(error);
      }
    };

    getNfts();
  }, [setErrorMsg]);

  useEffect(() => {
    console.log({ myNfts });
  }, [myNfts]);

  // Search By Name

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredNfts = myNfts?.filter((nft) =>
    nft.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort by price & creation_date

  const sortedNfts = [...filteredNfts].sort((a, b) => {
    if (sortType === "تاريخ الإنشاء تصاعدي") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    if (sortType === "تاريخ الإنشاء تنازلي") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    if (sortType === "سعر تنازلي") {
      return b.price - a.price;
    }
    if (sortType === "سعر تصاعدي") {
      return a.price - b.price;
    }
    return 0;
  });

  // Render the NFTs based on the selected category
  const filteredNFTsByCategory = sortedNfts.filter((nft) => {
    if (category === "") {
      return true; // Show all NFTs if no category is selected
    } else {
      return nft.category === category;
    }
  });

  return (
    // Trendy collections
    <section className="py-[100px] bg-gray-100 block">
      {/* Container */}
      <div className="flex flex-col items-center mb-8">
        <h3 className="font-bold text-xl text-center xl:text-right">
          العدد الإجمالي للرموز
        </h3>
        <div className="flex gap-2">
          <p className="text-lg text-gray-500 font-medium">
            {myNftsCount > 1 ? `رموز` : `رمز`}
          </p>
          <p className="text-lg text-gray-500 font-medium">{myNftsCount}</p>
        </div>
      </div>
      <div className="max-w-full px-16 2xl:px-36  ">
        {/* row */}
        <div className="">
          {/* col */}
          <div className="max-w-full">
            {/* dop category */}
            <div
              className="border shadow-slate-400 ... rounded-xl bg-white mb-16 px-4  md:mb-[60px] md:p-8 md:flex md:flex-wrap md:justify-center"
              style={{ direction: "rtl" }}
            >
              <div className="grid grid-cols-2 md:flex md:justify-between">
                {/* Search button */}
                {/* {open ? ( */}
                {/* <div
                  className="flex justify-center"
                  style={{ direction: "ltr" }}
                  onClick={() => setOpen(false)}
                >
                  <GenericButton
                    title="بحث عن طريق الإسم"
                    btnStyle="bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED] p-4 my-4 w-[150px] xl:w-[200px] xl:mr-8"
                    textStyle="px-4 xl:block"
                    isIcon
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      className="fill-white group-hover:fill-white duration-300"
                    >
                      <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
                    </svg>
                  </GenericButton>
                </div>
                ) : ( */}

                {/* ------- Search NFT by name ------ */}
                <input
                  type="text"
                  placeholder="بحث عن طريق الإسم"
                  value={searchTerm}
                  onChange={handleChange}
                  className="pr-5 shadow-lg h-14 mt-4 ml-5 border border-gray-200 rounded-3xl"
                />
                <DeleteIcon onClick={() => setSearchTerm("")} />
                {/* )} */}
                {/*-------- NFTs  كل الأصناف ---------*/}
                <DropDown
                  ref={categoryRef}
                  name="كل الأصناف"
                  options={[
                    "تطبيقات جوالة",
                    "ذكاء اصطناعي",
                    "قصص",
                    "ألعاب تعليمية",
                    "صور",
                    "قصائد",
                    "الخط لعربي",
                  ]}
                  onSelect={(choice) => setCategory(choice)}
                />
                <DeleteIcon
                  onClick={() => {
                    setCategory("");
                    categoryRef.current.innerHTML = "كل الأصناف";
                  }}
                />

                {/*---------  ترتيب حسب ----------*/}
                <DropDown
                  ref={sortTypeRef}
                  name="ترتيب حسب"
                  options={[
                    "تاريخ الإنشاء تصاعدي",
                    "تاريخ الإنشاء تنازلي",
                    "سعر تنازلي",
                    "سعر تصاعدي",
                  ]}
                  onSelect={(choice) => {
                    setSortType(choice);
                  }}
                />
                <DeleteIcon
                  onClick={() => {
                    setSortType("");
                    sortTypeRef.current.innerHTML = "ترتيب حسب";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* List of cards */}
        {isLoading ? (
          <Loading />
        ) : (
          <div
            className="flex flex-row flex-wrap justify-center gap-8"
            style={{ direction: "rtl" }}
          >
            {filteredNFTsByCategory.map((nft) => {
              return <NFTcard key={v4()} item={nft} />;
            })}
          </div>
        )}
        {/* ------------ Button عرض المزيد ----------------*/}
        <div className="flex justify-center">
          <GenericButton
            title="عرض المزيد"
            btnStyle="w-[200px] h-[55px] mt-[40px] bg-black hover:bg-violet-600"
            textStyle="pt-5"
            isIcon={false}
          />
        </div>
      </div>
    </section>
  );
}

export default CategorySelect;
