import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import GenericButton from "../components/shared/GenericButton";
import { login, reset } from "../store/features/auth-moderator/authSlice";
import Loading from "./Loading";

function LoginModerator() {
  const errRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({ email: "", password: "" });
  const { email, password } = formData;
  const [errMsg, setErrMsg] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.authModerator
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
      console.log({ message });
    }

    if (isSuccess && user) {
      console.log(user);
      navigate("/dashboard");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
    };

    setFormErrors(validate(userData));
    dispatch(login(userData));
    setIsSubmit(true);
  };

  useEffect(() => {
    console.log({ formErrors });
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log({ email, password });
      console.log(formData);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "البريد الالكتروني مطلوب!";
    } else if (!regex.test(values.email)) {
      errors.email = "هذا ليس تنسيق بريد إلكتروني صالح!";
    }

    if (!values.password) {
      errors.password = "كلمة المرور مطلوبة";
    } else if (values.password.length < 8) {
      errors.password = "يجب أن تكون كلمة المرور أكثر من 8 أحرف";
    }

    return errors;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="h-[70vh] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12">
      <div className="flex justify-center">
        <form className="w-full lg:w-1/3 bg-white p-6 rounded-lg">
          <p ref={errRef}>{errMsg}</p>
          <h1 className="font-Tajawal font-bold text-5xl text-right antialiased pb-10">
            تسجيل دخول المشرف
          </h1>

          {/* {Object.keys(formErrors).length === 0 && isSubmit ? (
            <div className="text-right text-violet-500 font-bold mb-7 text-lg">
              تم تسجيل الدخول بنجاح
            </div>
          ) : // <pre>{JSON.stringify(formData, undefined, 2)}</pre>
          null} */}
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              البريد الإلكتروني
            </label>

            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
              className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold"
            />
            {formErrors.email && (
              <p className="text-red-800 text-right py-5">{formErrors.email}</p>
            )}
          </div>

          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              كلمة المرور
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handleChange}
              required
              className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold"
            />
            {formErrors.password && (
              <p className="text-red-800 text-right py-5">
                {formErrors.password}
              </p>
            )}
          </div>
          <div className="text-right my-5 text-xl">
            {/* <p>مساعدة</p> */}
            <p className="my-5 text-violet-600 hover:text-fuchsia-600">
              <Link to={"/forgot-password-moderator"}> فقدت كلمة المرور</Link>
            </p>
          </div>
          <div className="flex justify-center">
            <GenericButton
              title="دخول"
              btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
              textStyle="px-6"
              isIcon={false}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginModerator;
