// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  display: flex;
  flex-direction: row;
  direction: rtl;
}
.dashboard-body {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
}
`, "",{"version":3,"sources":["webpack://./src/AppDashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B","sourcesContent":[".dashboard-container {\n  display: flex;\n  flex-direction: row;\n  direction: rtl;\n}\n.dashboard-body {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  background-color: #f0f2f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
