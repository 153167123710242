import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import userImgPlaceHolder from "../../assets/icons/icons8-user-90.svg";

// import PDF from "../../components/Icons/PDF";

import CopyableText from "../../components/shared/CopyableText";
import Loading from "../Loading";

import useNftStoreZustand from "../../store/useNftStoreZustand";
import { nftApi } from "../../api/api";
import ReportsTable from "./Reports-table";
import categories from "../../constants/categories";
import { FiExternalLink } from "react-icons/fi";

function NftReports() {
  const { fetchNFTById, nftByIdRes, loading } = useNftStoreZustand((state) => ({
    fetchNFTById: state.fetchNFTById,
    nftByIdRes: state.nftByIdRes,
    loading: state.loading,
  }));
  const [reports, setReports] = useState([]);
  const { tokenId } = useParams();

  useEffect(() => {
    fetchNFTById(tokenId);
  }, [fetchNFTById, tokenId]);

  useEffect(() => {
    const getReports = async () => {
      const { data } = await nftApi.getNftsReports(nftByIdRes._id);
      setReports(data);
    };
    getReports();
  }, [nftByIdRes]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className=" p-8 flex flex-col justify-start items-center bg-[#fff]">
            {nftByIdRes && (
              <div className="w-[90vw] md:w-[750px] lg:w-[970px] xl:w-[1200px] flex flex-row justify-between gap-12 text-right ">
                <img
                  className="w-1/2 rounded-xl object-fill object-center "
                  src={
                    nftByIdRes.image ||
                    "https://ipfs.io/ipfs/bafybeiesm3c2e3p33oc32ntmpnden3om43c435g7hykqku5vmzhzxzusvy"
                  }
                />
                <div
                  className="w-1/2 flex flex-col gap-8 "
                  style={{ direction: "rtl" }}
                >
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col  ">
                      <h3 className="text-3xl font-bold">
                        {nftByIdRes.name || "الكسو NFT"}
                      </h3>
                      <a
                        href={
                          `https://testnet.ftmscan.com/token/${process.env.REACT_APP_ALECSO_NFT_ADDRESS}?a=${nftByIdRes.tokenId}` ||
                          "tokenId"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="w-[15vw] truncate text-lg"
                        style={{ direction: "ltr" }}
                      >
                        {`🔗 https://testnet.ftmscan.com/token/${process.env.REACT_APP_ALECSO_NFT_ADDRESS}?a=${nftByIdRes.tokenId}`}
                      </a>
                    </div>
                    {nftByIdRes.status === "onSale" && (
                      <img
                        src={require("../../assets/images/nft for sale.png")}
                        className="w-[6vw] "
                      />
                    )}
                  </div>
                  <p className="font-semibold">الصنف: </p>
                  <p className="font-thin text-[#7D7D7D] leading-tight">
                    {nftByIdRes.category || categories[0]}
                  </p>
                  <p className="font-semibold">الوصف: </p>
                  <p className="font-thin text-[#7D7D7D] leading-tight">
                    {nftByIdRes.description ||
                      "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبورأنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريدأكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس أيا كوممودو كونسيكيوات . ديواسأيوتي أريري دولار إن ريبريهينديرأيت فوليوبتاتي فيلايت أيسسي كايلليوم دولار أيو فيجايتنيولا باراياتيور. أيكسسيبتيور ساينت أوككايكات كيوبايداتات نون بروايدينت ,سيونت ان كيولباكيو أوفيسيا ديسيريونتموليت انيم أيدي ايست لابوريوم."}
                  </p>
                  <div className="flex gap-6">
                    <img
                      src={nftByIdRes.owner?.photo || userImgPlaceHolder}
                      alt="avatar"
                      className="w-20 h-20 object-cover rounded-full "
                    />
                    <div className="flex flex-col justify-around items-start ">
                      <p className="text-gray-900 font-thin text-sm ">
                        المالك الحالي
                      </p>
                      <p className="text-black font-bold text-lg ">
                        {`${nftByIdRes.owner?.name}` || "أحمد"}
                      </p>
                      <div className="flex flex-row gap-2 text-gray-900 font-thin text-sm ">
                        <CopyableText
                          text={
                            nftByIdRes.owner?.wallet_address || "0x2601..57"
                          }
                        ></CopyableText>
                      </div>
                    </div>
                  </div>
                  <div className="w-5/6 flex flex-row gap-10">
                    {/* <div className="w-full flex flex-col items-center bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25]">
                      <h6 className="font-bold mb-2">تحقق من ملف PDF</h6>
                      <a
                        href={
                          nftByIdRes.file ||
                          "https://ipfs.io/ipfs/bafkreih5mdlzgq2d6zouc4sgq4aperer5jv3vug3nqznellizzvaztitia"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PDF />
                      </a>
                    </div> */}
                    <div className="w-1/3 flex flex-col items-center bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25]">
                      <h6 className="font-bold mb-2">رابط المشروع</h6>
                      <a
                        href={`${nftByIdRes.projectLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full text-sm truncate items-center"
                        style={{ direction: "ltr" }}
                      >
                        <FiExternalLink className="inline mr-2" />
                        {nftByIdRes.projectLink}
                      </a>
                    </div>
                    <div className="w-full flex flex-row items-center gap-3 bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25]">
                      <img
                        src={nftByIdRes.creator?.photo || userImgPlaceHolder}
                        alt="avatar"
                        className="w-20 h-20 object-cover rounded-full "
                      />
                      <div className="flex flex-col justify-around items-start ">
                        <p className="text-black font-bold text-lg ">
                          {`${nftByIdRes.creator?.name}` || "أحمد"}
                        </p>
                        <p className="text-gray-900 font-thin text-sm ">
                          منشئ الرّمز
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full">
            <ReportsTable reports={reports} />
          </div>
        </div>
      )}
    </>
  );
}

export default NftReports;
