import React, { useState, forwardRef } from "react";
import { v4 } from "uuid";

// eslint-disable-next-line react/display-name
const DropDown = forwardRef(({ name, options, onSelect }, ref) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const handleSelect = (value) => {
    setSelected(value);
    if (onSelect) {
      onSelect(value);
    }
  };

  // const handleClickOutside = (event) => {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <div className="md:mx-2 xl:mx-6">
      <button
        ref={ref}
        type="button"
        className="relative inline-block mt-4 py-3 px-5 border border-slate-200 shadow-lg shadow-slate-200 ... rounded-[30px] w-full bg-white text-sm text-black font-normal after:content-['⌄'] after:text-lg after:font-semibold after:text-violet-600 after:pr-16"
        onClick={() => setOpen(!open)}
      >
        {selected || name}
      </button>
      {open && (
        <div className="absolute flex flex-col justify-around bg-[#f8f8f8] font-medium cursor-pointer w-[190px] text-gray-500 z-10">
          {options.map((option) => (
            <button
              type="button"
              className="border border-gray-200 py-2 text-right pr-4 hover:bg-fuchsia-400 hover:text-white hover:font-bold"
              key={v4()}
              onClick={() => {
                handleSelect(option);
                setOpen(!open);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
});

export default DropDown;
