import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useNftStoreZustand from "../store/useNftStoreZustand";
import Loading from "./Loading";
import "./Orders/styles.css";
// import DoneIcon from "../assets/icons/done.svg";
// import CancelIcon from "../assets/icons/cancel.svg";
// import RefundedIcon from "../assets/icons/refunded.svg";

function NftRequests() {
  const navigate = useNavigate();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const { fetchRequests, fetchRequestsRes, loading } = useNftStoreZustand(
    (state) => ({
      fetchRequests: state.fetchRequests,
      fetchRequestsRes: state.fetchRequestsRes,
      loading: state.loading,
    })
  );

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    if (Object.keys(fetchRequestsRes).length !== 0) {
      console.log({ fetchRequestsRes });
      setNftRequests(fetchRequestsRes);
    }
  }, [fetchRequestsRes]);

  const [nftRequests, setNftRequests] = useState([]);

  const [search, setSearch] = useState("");

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = nftRequests.filter(
        (item) =>
          item.first_name.toLowerCase().includes(search.toLowerCase()) ||
          item.last_name.toLowerCase().includes(search.toLowerCase()) ||
          item.product.toLowerCase().includes(search.toLowerCase())
      );
      setNftRequests(search_results);
    } else {
      setNftRequests(fetchRequestsRes);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboard-content">
          <div className="dashboard-content-container">
            <div className="dashboard-content-header">
              <h2>قائمة الطلبات</h2>
              <div className="dashboard-content-search">
                <input
                  type="text"
                  value={search}
                  placeholder="بحث.."
                  className="dashboard-content-input"
                  onChange={(e) => __handleSearch(e)}
                />
              </div>
            </div>

            <table>
              <thead>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  اسم الرمز
                </th>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  اسم الطالب
                </th>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  تاريخ الطلب
                </th>
              </thead>

              {/* {nftRequests?.map((item, index) => (
            <tr key={index}>
              <td>{item._id}</td>
              <td>{item.name}</td>

              <td>{item.first_name}</td>
              <td>{item.createdAt}</td>
            </tr>
          ))} */}

              {nftRequests.length !== 0 ? (
                <tbody>
                  {nftRequests.map((nft) => (
                    <tr
                      onClick={() => navigate(`${nft._id}`)}
                      key={nft._id}
                      className="cursor-pointer"
                    >
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>{nft.name}</span>
                      </td>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>{`${nft.owner?.name}`}</span>
                      </td>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>
                          {new Date(nft.createdAt).toLocaleDateString(
                            "ar-TN",
                            options
                          )}
                        </span>
                      </td>
                      {/* <td>
                        <div>
                          {order.status === "Paid" ? (
                            <img
                              src={DoneIcon}
                              alt="paid-icon"
                              className="dashboard-content-icon"
                            />
                          ) : order.status === "Canceled" ? (
                            <img
                              src={CancelIcon}
                              alt="canceled-icon"
                              className="dashboard-content-icon"
                            />
                          ) : order.status === "Refunded" ? (
                            <img
                              src={RefundedIcon}
                              alt="refunded-icon"
                              className="dashboard-content-icon"
                            />
                          ) : null}
                          <span>{order.status}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <img
                            src={order.avatar}
                            className="dashboard-content-avatar"
                            alt={order.first_name + " " + order.last_name}
                          />
                          <span>
                            {order.first_name} {order.last_name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span>{order.product}</span>
                      </td>
                      <td>
                        <span>${order.price}</span>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>

            {nftRequests.length === 0 && (
              <div className="dashboard-content-footer">
                <span className="empty-table">لايوجد بيانات</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NftRequests;
