import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";
import AlecsoLogo from "../assets/images/logo/logo-alecso.png";
import walletIcon from "../assets/images/icon/connect-wallet.svg";
import GenericButtonMetamask from "../components/shared/GenericButtonMetamask";
import Web3 from "web3";
import useStudentStoreZus from "../store/useStudentStoreZus";

function Navbar() {
  const [show, setShow] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [heightMenu, setHeightMenu] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [network, setNetwork] = useState("");
  const [account, setAccount] = useState("");

  // const fetch = useStudentStoreZus((state) => state.fetch);
  // const loading = useStudentStoreZus((state) => state.loading);
  // const fetchErrors = useStudentStoreZus((state) => state.errors);
  const { loginStudentRes, loginStudent, logoutStudent } = useStudentStoreZus(
    (state) => ({
      loginStudentRes: state.loginStudentRes,
      loginStudent: state.loginStudent,
      logoutStudent: state.logoutStudent,
    })
  );

  const refMenuMobile = useRef(null);
  useLayoutEffect(() => {
    setHeightMenu(refMenuMobile.current.scrollHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    });

    const checkNetwork = async () => {
      // Connect to the user's selected network
      const web3 = new Web3(window.ethereum);
      const networkId = await web3.eth.net.getId();

      if (networkId === 4002) {
        setNetwork("Fantom Testnet");
        const currentAccount = await web3.eth.getAccounts();
        if (currentAccount?.length === 0) {
          setAccount("");
          setIsConnected(false);
          logoutStudent();
          return;
        }
        await loginStudent({ wallet: currentAccount[0] });
      } else {
        setNetwork("Not connected to Fantom Testnet");
        setIsConnected(false);
        logoutStudent();
      }
    };

    const checkAccount = async () => {
      const web3 = new Web3(window.ethereum);
      const currentAccount = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();

      if (currentAccount?.length === 0 || networkId !== 4002) {
        setAccount("");
        setIsConnected(false);
        logoutStudent();
        return;
      }
      const res = await loginStudent({ wallet: currentAccount[0] });

      if (
        res ===
        "خطأ: لا يوجد حساب مسجل. للتسجيل بمعرف طالبك، يرجى الحصول على توكين ألكسو أولي من خلال  صفحة: المشاركة عبر الأسبوع العربي للبرمجة"
      ) {
        setIsConnected(false);
      }
      const truncatedText = currentAccount[0]?.substring(0, 6);
      const truncatedText2 = currentAccount[0]?.substring(
        currentAccount[0]?.length,
        currentAccount[0]?.length - 2
      );
      setAccount(`${truncatedText}...${truncatedText2}`);
    };

    if (window.ethereum) {
      window.ethereum.on("chainChanged", checkNetwork);
      window.ethereum.on("accountsChanged", checkAccount);
      window.ethereum.on("disconnect", checkAccount);

      checkNetwork();
      checkAccount();
    }
  }, []);

  useEffect(() => {
    setIsConnected(
      Object.keys(loginStudentRes).length !== 0 && network === "Fantom Testnet"
    );
  }, [loginStudentRes]);

  // Connect to MetaMask
  const connectMetamask = async () => {
    try {
      // Check if MetaMask is installed
      if (window.ethereum) {
        // try {
        //   // ask to change to fantom network
        //   await window.ethereum.request({
        //     method: "wallet_switchEthereumChain",
        //     params: [{ chainId: "0xfa" }],
        //   });
        // } catch (error) {
        //   if (error.code === 4001) {
        //     // User rejected request
        //   }
        // }
        try {
          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
        } catch (error) {
          if (error.code === 4001) {
            // User rejected request
          }
        }
      } else {
        throw new Error("MetaMask is not installed.");
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  return (
    <header
      className={`font-bold text-lg top-0 left-0 w-full bg-white border border-b-gray-200 ${
        fixed && "fixed z-40"
      }`}
    >
      <div className="mx-auto py-5">
        <div className="flex  items-center justify-around  md:space-x-11  md:justify-center ">
          {/* logo facebook */}

          {/* button connect wallet */}
          {window.ethereum ? (
            <GenericButtonMetamask
              onClick={connectMetamask}
              title={
                isConnected ? `${network}\n${account}` : "ربط المحفظة الرقمية"
              }
              btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
              textStyle="hidden px-6 xl:block"
              isIcon
            >
              <img src={walletIcon} alt="wallet icon" />
            </GenericButtonMetamask>
          ) : (
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              <GenericButtonMetamask
                title="Please install metamask"
                btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                textStyle="hidden px-6 xl:block"
                isIcon={false}
              />
            </a>
          )}

          {/* navigation menu - desktop */}

          <nav className=" hidden md:block ">
            <ul className="flex  justify-around md:space-x-6">
              <li className="hover:text-fuchsia-500">
                <a
                  href="https://arabcodeweek.alecso.org"
                  target={`_blank`}
                  rel="noreferrer"
                  className="hover:text-fuchsia-500 focus:text-fuchsia-500"
                >
                  اتصل بنا
                </a>
              </li>
              <li className=" group">
                <button className="hover:text-fuchsia-500 focus:text-fuchsia-500 before:content-['⌄']">
                  مسؤول
                </button>
                <ul className="absolute w-64 bg-white rounded-md opacity-0 transition-all duration-1000 group-hover:opacity-100 translate-y-[15px] text-right -z-50 group-hover:z-50">
                  <li>
                    <Link
                      to="login"
                      className="block p-5 hover:text-fuchsia-500 focus:text-fuchsia-500 hover:-translate-x-4 transition duration-300"
                      style={{
                        direction: "rtl",
                      }}
                    >
                      مدير
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/login-moderator"
                      className="block p-5 hover:text-fuchsia-500 focus:text-fuchsia-500 hover:-translate-x-4 transition duration-300"
                      style={{ direction: "rtl" }}
                    >
                      مشرف
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  to="add-nft"
                  className="hover:text-fuchsia-500 focus:text-fuchsia-500"
                  style={{
                    direction: "rtl",
                  }}
                >
                  أضف NFT
                </Link>
              </li>
              <li className=" group">
                <button className="hover:text-fuchsia-500 focus:text-fuchsia-500 before:content-['⌄']">
                  عرض
                </button>
                <ul className="absolute w-64 bg-white rounded-md opacity-0 transition-all duration-1000 group-hover:opacity-100 translate-y-[15px] text-right -z-50 group-hover:z-50">
                  <li>
                    <Link
                      to="nfts"
                      className="block p-5 hover:text-fuchsia-500 focus:text-fuchsia-500 hover:-translate-x-4 transition duration-300"
                      style={{
                        direction: "rtl",
                      }}
                    >
                      _ رموز الـNFT
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="student-nfts"
                      className="block p-5 hover:text-fuchsia-500 focus:text-fuchsia-500 hover:-translate-x-4 transition duration-300"
                      style={{ direction: "rtl" }}
                    >
                      _ رموز NFT الخاصة بي
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="">
                <Link
                  to="faucet"
                  className="hover:text-fuchsia-500  focus:text-fuchsia-500"
                >
                  تسجيل مستخدم جديد
                </Link>
              </li>
              <li className="">
                <Link
                  to="faucet-old"
                  className="hover:text-fuchsia-500  focus:text-fuchsia-500"
                >
                  المشاركة عبر الأسبوع العربي للبرمجة
                </Link>
              </li>
              <li className=" items-baseline">
                <Link
                  to=""
                  className="text-black hover:text-fuchsia-500  focus:text-fuchsia-500"
                >
                  الرئيسية
                </Link>
              </li>
            </ul>
          </nav>

          {/* logo ALECSO */}

          <Link to="/">
            <img src={AlecsoLogo} alt="ALECSO logo" width={80} height={80} />
          </Link>

          {/* button naviagtion menu - mobile */}

          <button
            type="button"
            className="md:hidden"
            onClick={() => setShow((prev) => !prev)}
          >
            <div
              className={`bg-black w-6 h-[3px] m-1 transition-all duration-700 ${
                show && "rotate-45 translate-y-[7px]"
              } `}
            />
            <div
              className={`bg-black w-6 h-[3px] m-1 transition-opacity duration-700 ${
                show && "opacity-0"
              } `}
            />
            <div
              className={`bg-black w-6 h-[3px] m-1 transition-all duration-700  ${
                show && "-rotate-45 -translate-y-[7px] "
              }`}
            />
          </button>
        </div>
        {/* mobile menu */}
        <div
          ref={refMenuMobile}
          style={{ height: show ? heightMenu : 0 }}
          className="absolute md:hidden space-y-4 z-50  bg-white transition-h duration-1000 overflow-hidden mt-4 w-full px-3 font-medium"
        >
          <div className="border-b hover:text-fuchsia-500 focus:text-fuchsia-500 ">
            <Link to="">الرئيسية</Link>
          </div>
          <div className="border-b hover:text-fuchsia-500 focus:text-fuchsia-500 ">
            <Link to="">تقديم المشروع</Link>
          </div>
          <div className="border-b hover:text-fuchsia-500 focus:text-fuchsia-500 ">
            <Link to="">عرض</Link>
          </div>
          <div className="border-b hover:text-fuchsia-500 focus:text-fuchsia-500 ">
            <Link to="">NFT أضف</Link>
          </div>
          <div className=" hover:text-fuchsia-500">
            <Link to="/login">اتصل بنا</Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
