import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { nftApi } from "../api/api";
import GenericButton from "./shared/GenericButton";
import useModalZustand from "../store/useModalZustand";
import useStudentStoreZus from "../store/useStudentStoreZus";

const ReportModal = ({ nftId }) => {
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);
  const setSuccessMsg = useModalZustand((state) => state.setSuccessMsg);
  const loginStudentRes = useStudentStoreZus((state) => state.loginStudentRes);

  const [showModal, setShowModal] = useState(false);
  // const [email, setEmail] = useState("");
  // const [emailError, setEmailError] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [reportedTwiceError, setReportedTwiceError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    // setEmail("");
    // setEmailError(false);
    setReason("");
    setReasonError(false);
    setReportedTwiceError("");
  };

  const reportNft = async () => {
    const res = await nftApi.reportNft(
      nftId,
      JSON.stringify({
        email: loginStudentRes?.email,
        reason: reason,
      })
    );
    return res;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    // const emailRegex =
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!reason) {
      // if (!email) setEmailError("يجب عليك إدخال بريد إلكتروني صالح");
      // else if (!reason) setReasonError(" يجب عليك إدخال سبب");
      // } else if (!emailRegex.test(email.toLocaleLowerCase())) {
      //   setEmailError("يجب عليك إدخال بريد إلكتروني صالح");
      setReasonError(" يجب عليك إدخال سبب");
    } else {
      setLoading(true);

      try {
        const res = await reportNft();
        setLoading(false);
        if (res === "You have already reported this nft") {
          setReportedTwiceError(
            "لقد أبلغت بالفعل عن هذا nft ، لا يمكنك الإبلاغ عن نفس nft مرتين"
          );
        } else {
          handleClose();
          setSuccessMsg("تم إرسال التقرير بنجاح");
        }
      } catch (error) {
        setLoading(false);

        console.log({ error });
        return setErrorMsg(error);
      }
    }
  }
  return (
    <div className="relative">
      <div className="  text-red-500 font-bold">
        <button
          className="flex items-center gap-1"
          onClick={() => setShowModal(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
          </svg>
          <p className="text-lg mt-1">تقرير</p>
        </button>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-black opacity-75"></div>
      )}
      {showModal && (
        <div className="fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg w-screen md:w-[600px] ">
            <div
              className="w-full fle
             justify-end"
            >
              <button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
            <form
              className="w-full bg-white p-6 rounded-lg "
              onSubmit={handleSubmit}
            >
              {/* <div className="mb-4 w-full">
                <label className="block text-gray-700 font-medium text-xl text-right mb-2">
                  أدخل البريد الإلكتروني
                </label>
                <input
                  placeholder="... البريد الإلكتروني"
                  className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold ${
                    emailError ? "border-red-600" : ""
                  }`}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                    setReportedTwiceError("");
                  }}
                />
                <div className="w-full flex  mt-2 text-red-600">
                  {emailError}
                </div>
              </div> */}
              <div className="mb-4">
                <label className="block text-gray-700 font-medium text-xl text-right mb-2">
                  أدخل السبب
                </label>
                <textarea
                  placeholder="... السبب"
                  className={`w-full h-36 flex flex-row justify-between items-center appearance-none relative px-8 py-4 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold ${
                    reasonError ? "border-red-600" : ""
                  }`}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                    setReasonError("");
                  }}
                />
                <div className="w-full flex  mt-2 text-red-600">
                  {reasonError}
                </div>
              </div>
              <div className="flex justify-center gap-1">
                <GenericButton
                  title="أرسل تقرير"
                  btnStyle={`p-4 ${
                    loading
                      ? "bg-slate-400"
                      : "bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                  }`}
                  textStyle="px-6"
                  isIcon={false}
                  onClick={handleSubmit}
                  disabled={loading}
                />
                <button
                  type="button"
                  onClick={handleClose}
                  className="bg-black text-white rounded-full w-1/5 font-bold"
                >
                  إلغاء
                </button>
              </div>

              <div className="w-full text-red-600 mt-6">
                {reportedTwiceError}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

ReportModal.propTypes = {
  nftId: PropTypes.string.isRequired,
};

export default ReportModal;
