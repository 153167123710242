import React, { useState, useEffect } from "react";
import { calculateRange, sliceData } from "../../../utils/table-pagination";

import "./styles.css";
import DoneIcon from "../../../assets/icons/done.svg";
import CancelIcon from "../../../assets/icons/cancel.svg";
import RefundedIcon from "../../../assets/icons/refunded.svg";
import ReportDetailsModal from "../ReportDetailsModal";

function ReportsTable({ reports }) {
  const [search, setSearch] = useState("");
  const [nftReports, setNftReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [showReportDetails, setShowReportDetails] = useState(false);
  const [selectedReport, setSelectedReport] = useState();

  useEffect(() => {
    setPagination(calculateRange(reports, 5));
    setNftReports(sliceData(reports, page, 5));
  }, []);

  useEffect(() => {
    setNftReports(reports);
  }, [reports]);

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = nftReports.filter(
        (item) =>
          item.first_name.toLowerCase().includes(search.toLowerCase()) ||
          item.last_name.toLowerCase().includes(search.toLowerCase()) ||
          item.product.toLowerCase().includes(search.toLowerCase())
      );
      setNftReports(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setNftReports(sliceData(reports, new_page, 5));
  };

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>قائمة التقارير</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="بحث.."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>البريد الإلكتروني</th>
            <th>تاريخ التقرير</th>
            <th>وضع التقرير </th>
          </thead>

          {nftReports.length !== 0 ? (
            <tbody>
              {nftReports?.map((report, index) => (
                <tr
                  key={index}
                  className="cursor-pointer hover:bg-slate-300"
                  onClick={() => {
                    if (report.status === "pending") {
                      setShowReportDetails(true);
                      setSelectedReport(report);
                    }
                  }}
                >
                  <td>
                    <span>{report?.email}</span>
                  </td>

                  <td>
                    <div className="flex justify-end">
                      <span>{report?.createdAt.split("T")[0]}</span>
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-end">
                      {report?.status === "approved" ? (
                        <img
                          src={DoneIcon}
                          alt="paid-icon"
                          className="dashboard-content-icon"
                        />
                      ) : report?.status === "declined" ? (
                        <img
                          src={CancelIcon}
                          alt="canceled-icon"
                          className="dashboard-content-icon"
                        />
                      ) : report.status === "pending" ? (
                        <img
                          src={RefundedIcon}
                          alt="refunded-icon"
                          className="dashboard-content-icon"
                        />
                      ) : null}
                      <span
                        className={`${
                          report?.status === "accepted"
                            ? "text-green-600"
                            : report?.status === "pending"
                            ? "text-orange-500"
                            : report?.status === "declined"
                            ? "text-red-600"
                            : ""
                        }`}
                      >
                        {report?.status}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {reports.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
      <ReportDetailsModal
        showModal={showReportDetails}
        setShowModal={setShowReportDetails}
        report={selectedReport}
        setReport={setSelectedReport}
      />
    </div>
  );
}

export default ReportsTable;
