import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import "./Orders/styles.css";
import useModeratorStoreZus from "../store/useModeratorStoreZus";
// import DoneIcon from "../assets/icons/done.svg";
// import CancelIcon from "../assets/icons/cancel.svg";
// import RefundedIcon from "../assets/icons/refunded.svg";
import { FiEdit, FiDelete } from "react-icons/fi";

function Moderators() {
  const navigate = useNavigate();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const { fetchAllModerators, deleteModerator, data, loading } =
    useModeratorStoreZus((state) => ({
      fetchAllModerators: state.fetchAllModerators,
      deleteModerator: state.deleteModerator,
      deleteRes: state.deleteRes,
      data: state.data,
      loading: state.loading,
    }));

  useEffect(() => {
    fetchAllModerators();
  }, [fetchAllModerators]);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      console.log({ data });
      setModerators(data);
    }
  }, [data]);

  const [moderators, setModerators] = useState([]);

  const [search, setSearch] = useState("");

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = moderators.filter(
        (item) =>
          item.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.lastName.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase())
      );
      setModerators(search_results);
    } else {
      setModerators(data);
    }
  };

  const deleteMod = async (moderator) => {
    console.log({ moderator });
    await deleteModerator({ id: moderator._id });
    window.location.reload();
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboard-content">
          <div className="dashboard-content-container">
            <div className="dashboard-content-header">
              <h2>قائمة المشرفين</h2>
              <div className="dashboard-content-search">
                <input
                  type="text"
                  value={search}
                  placeholder="بحث.."
                  className="dashboard-content-input"
                  onChange={(e) => __handleSearch(e)}
                />
              </div>
            </div>

            <table>
              <thead>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  اسم المشرف
                </th>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  البريد إلكتروني
                </th>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  اسم الجامعة
                </th>
                <th style={{ textAlign: "right", direction: "rtl" }}>
                  تاريخ الطلب
                </th>
                <th></th>
              </thead>

              {moderators.length !== 0 ? (
                <tbody>
                  {moderators.map((moderator) => (
                    <tr key={moderator._id}>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>{`${moderator.firstName} ${moderator.lastName}`}</span>
                      </td>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>{`${moderator.email}`}</span>
                      </td>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>{`${moderator.university}`}</span>
                      </td>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <span>
                          {new Date(moderator.createdAt).toLocaleDateString(
                            "ar-TN",
                            options
                          )}
                        </span>
                      </td>
                      <td style={{ textAlign: "right", direction: "rtl" }}>
                        <button
                          onClick={() =>
                            navigate(`../edit-moderator/${moderator._id}`, {
                              state: moderator,
                            })
                          }
                          className="ml-2"
                        >
                          <FiEdit />
                        </button>
                        <button>
                          <FiDelete onClick={() => deleteMod(moderator)} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>

            {moderators.length === 0 && (
              <div className="dashboard-content-footer">
                <span className="empty-table">لايوجد بيانات</span>
              </div>
            )}

            <div className="dashboard-content-footer">
              <button
                onClick={() => navigate("/dashboard-admin/add-moderator")}
                className="w-[10vw] h-[4vh] bg-green-600 rounded-lg shadow-md border border-green-500 text-white"
              >
                أضف مشرف
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Moderators;
