import axios from "axios";

function ErrorException(obj) {
  this.message = obj.message || "";
  this.response = obj.response || {};
}

// Create moderator
const addModerator = async (moderatorData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_IP}/auth/createModerator`,
      moderatorData,
      config
    );

    console.log(JSON.stringify(response));

    return response.data;
  } catch (error) {
    console.log("Error........", error);
    throw new ErrorException(error);
  }
};

const moderatorService = { addModerator };

export default moderatorService;
