import React from "react";
import { Link, useMatches } from "react-router-dom";
import { v4 } from "uuid";

function BreadCrumbs() {
  const matches = useMatches();
  // console.log({ matches });
  const crumbs = matches
    .filter((elem) => Boolean(elem?.handle?.crumb))
    .map((elem) => elem?.handle?.crumb);
  // console.log({ crumbs });
  const title = crumbs[crumbs.length - 1].name;
  return (
    <section
      className="block py-20 bg-page-title bg-center bg-no-repeat bg-cover relative"
      //   style={{
      //     backgroundImage: "url('../assets/images/background/bg-page-title.png')",
      //     backgroundSize: "cover",
      //   }}
    >
      <div className="w-full h-full absolute inset-0 bg-gradient-to-r from-[#6345edd9] to-[#dc39fcd9]" />

      <div
        className="relative block text-right md:max-w-full md:mx-20 md:flex md:justify-between md:items-center xl:px-4 2xl:mx-64 2xl:px-4"
        style={{ direction: "rtl" }}
      >
        <h2 className=" mb-8 text-white text-6xl font-bold md:mb-0">{title}</h2>

        <div className=" flex justify-center">
          {crumbs.map((crumb, index) =>
            index < crumbs.length - 1 ? (
              <Link to={crumb.link} key={v4()}>
                <div className="text-white text-2xl pl-1 hover:text-black transition duration-700 hover:cursor-pointer after:content-['>'] after:text-2xl after:px-4 after:hover:text-white">
                  {`${crumb.name}`}
                </div>
              </Link>
            ) : (
              <div className="text-2xl font-bold text-white" key={v4()}>
                {crumb.name}
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
}

export default BreadCrumbs;
