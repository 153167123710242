import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logout as logoutModerator } from "../../store/features/auth-moderator/authSlice";
import { logout as logoutAdmin } from "../../store/features/auth/authSlice";

import SideBarItem from "./sidebar-item";

import "./styles.css";
// import logo from "../../assets/images/white-logo.png";
import LogoutIcon from "../../assets/icons/logout.svg";
import { useDispatch } from "react-redux";

function SideBar({ menu }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [active, setActive] = useState(0);

  useEffect(() => {
    menu.forEach((element) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
    });
  }, [location.pathname]);

  const __navigate = (id) => {
    setActive(id);
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-container">
        <div className="sidebar-logo-container">
          <h1 className="text-white text-5xl px-4 py-8 font-Tajawal font-bold">
            ألكسو
          </h1>
        </div>

        <div className="sidebar-container">
          <div className="sidebar-items">
            {menu.map((item, index) => (
              <div key={index} onClick={() => __navigate(item.id)}>
                <SideBarItem active={item.id === active} item={item} />
              </div>
            ))}
          </div>

          <div
            onClick={
              location.pathname.includes("dashboard-admin")
                ? () => {
                    dispatch(logoutAdmin());
                    navigate("/login");
                  }
                : () => {
                    dispatch(logoutModerator());
                    navigate("/login-moderator");
                  }
            }
            className="sidebar-footer"
          >
            <span className="sidebar-item-label">تسجيل خروج</span>
            <img
              src={LogoutIcon}
              alt="icon-logout"
              className="sidebar-item-icon"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
