/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { coinApi } from "../api/api";
import GenericButton from "../components/shared/GenericButton";
import useModalZustand from "../store/useModalZustand";
import Web3 from "web3";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import FileInput from "../components/Form/FileInput";
import TextInput from "../components/Form/TextInput";
import Dropdown from "../components/Form/Dropdown";
import arabicCountryNames from "../constants/arabicCountryNames";
import DateInput from "../components/Form/DateInput";

function Faucet() {
  const setSuccessMsg = useModalZustand((state) => state.setSuccessMsg);
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);

  const [errorWallet, setErrorWallet] = useState(false);
  const [walletInput, setWalletInput] = useState("");
  const [loading, setLoading] = useState(false);

  const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];

  const nftSchema = yup.object({
    nom: yup.string().required("الاسم مطلوب"),
    prenom: yup.string().required("اللقب مطلوب"),
    date_de_naissance: yup.string().required("تاريخ الميلاد مطلوب"),
    email: yup
      .string()
      .email("البريد الإلكتروني غير صالح")
      .required("البريد الإلكتروني مطلوب"),

    photo: yup
      .mixed()
      .test(
        "fileExists",
        // t("validation.photo.fileExists", "Image photo is required"),
        "الصورة المصغرة مطلوبة",
        (value) => {
          console.log({ photo: value });
          return value && value[0];
        }
      )
      .test(
        "fileSize",
        // t("validation.photo.fileSize", "Image size must be less than 5MB"),
        "يجب أن يكون حجم الصورة أقل من 5 ميغا بايت",
        (value) => value && value[0]?.size <= 5 * 1024 * 1024
      )
      .test(
        "fileType",
        // t("validation.photo.fileType", "File must be an image PNG or JPEG"),
        "يجب أن يكون الملف صورة بتنسيق PNG أو JPEG",
        (value) => value && ALLOWED_IMAGE_TYPES.includes(value[0]?.type)
      ),
  });
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(nftSchema),
  });

  const watchPhoto = watch("photo"); // you can supply default value as second argument

  const onSubmit = async (values) => {
    console.log("am i here");
    const isAddress = Web3.utils.isAddress(walletInput);
    if (!walletInput || !isAddress) {
      console.log("invalid wallet address");
      setErrorWallet(true);
    } else {
      // Use the data to create the
      console.log({ values });
      let formData = new FormData();

      formData.append("nom", values.nom);
      formData.append("prenom", values.prenom);
      formData.append("gender", values.gender);
      formData.append("date_de_naissance", values.date_de_naissance);
      formData.append("pays", values.pays);
      formData.append("email", values.email);
      formData.append("wallet", walletInput);

      formData.append("image", values.photo[0].name);
      formData.append("student", "true");

      formData.append("photo", values.photo[0]);

      setLoading(true);
      try {
        await coinApi.transferAlecsoCoin(formData);
        setSuccessMsg(
          "تمت إضافة عملة الالكسو بنجاح إلى محفظة Metamask الخاصة بك"
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log({ error });
        if (
          error.message ===
          "Student already exists and blocked from getting more coins"
        ) {
          setErrorMsg(
            "هذا المستخدم أو هذه المحفظة الرقمية تحصل(ت) على عملة رقمية من قبل بالتالي لم يعد بالامكان إضافة العملة مرّة أخرى"
          );
        } else {
          return setErrorMsg("خطأ في حفظ البيانات");
        }
      }

      setErrorWallet(false);
      setWalletInput("");
      reset();
    }
  };

  return (
    <>
      <div className="h-[auto] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12 text-right">
        <div className="flex justify-center ">
          <form
            className="w-full lg:w-1/3 bg-white p-6 rounded-lg "
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            encType="multipart/form-data"
          >
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <TextInput
                  type="text"
                  register={register}
                  errors={errors}
                  title="اللقب"
                  name="nom"
                />
                <TextInput
                  type="text"
                  register={register}
                  errors={errors}
                  title="الاسم"
                  name="prenom"
                />
              </div>
              <div className="flex flex-row gap-2">
                <Dropdown
                  register={register}
                  options={["ذكر", "أنثى"]}
                  title="الجنس"
                  name="gender"
                />

                <DateInput
                  type="date"
                  register={register}
                  errors={errors}
                  title="تاريخ الميلاد"
                  name="date_de_naissance"
                />
              </div>
              <TextInput
                type="text"
                register={register}
                errors={errors}
                title="البريد الإلكتروني"
                name="email"
              />

              <div className="mb-4">
                <input
                  placeholder="أدخل محفظتك الرقمية"
                  className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-black placeholder:font-bold ${
                    errorWallet ? "border-red-600" : ""
                  }`}
                  value={walletInput}
                  onChange={(e) => {
                    setWalletInput(e.target.value);
                    setErrorWallet(false);
                  }}
                />
              </div>
              <div className="flex flex-row gap-2">
                <FileInput
                  type="file"
                  register={register}
                  errors={errors}
                  title="اختر صورة ملفك الشخصي"
                  name="photo"
                  watch={watchPhoto}
                />
                <Dropdown
                  name="pays"
                  register={register}
                  options={arabicCountryNames}
                />
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <GenericButton
                title="التسجيل"
                btnStyle={`p-4 ${
                  loading
                    ? "bg-slate-400"
                    : "bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                }`}
                textStyle="px-6"
                isIcon={false}
                type="submit"
                disabled={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Faucet;
