import axios from "axios";

function ErrorException(obj) {
  this.message = obj.message || "";
  this.response = obj.response || {};
}

// Login user
const login = async (userData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_IP}/auth-moderator/loginAdmin`,
      userData
    );

    console.log(JSON.stringify(response?.data));

    if (response.data) {
      localStorage.setItem("user-moderator", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    console.log("authService.js ~ login ~ error: ", error);
    throw new ErrorException(error);
  }
};

// Logout user
const logout = () => {
  localStorage.removeItem("user-moderator");
};

// Update user's password
const updatePassword = async (pwdData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_IP}/auth-moderator/updateMyPassword`,
      pwdData,
      config
    );

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    console.log("authService.js ~ update password ~ error: ", error);
    throw new ErrorException(error);
  }
};

const authService = { login, logout, updatePassword };

export default authService;
