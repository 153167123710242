import { create } from "zustand";
import axios from "axios";

const useNftStoreZustand = create((set, get) => ({
  data: {},
  fetchCategoriesCountRes: [],
  fetchRequestsRes: {},
  respondNftRes: {},
  createNFtRes: {},
  nftByIdRes: {},
  sellNFTRes: {},
  buyNFTRes: {},
  loading: false,
  errors: "",
  fetchCategoriesCount: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/nft/getAllCategoriesCount`
      );
      // const myDATATA = [...response.data];
      set(() => ({ fetchCategoriesCountRes: response.data, loading: false }));

      console.log({ myDATATA: get().fetchCategoriesCountRes });
    } catch (err) {
      console.log("im not here ?");

      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  fetchRequests: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/nft/pending-nfts`
      );
      // const myDATATA = [...response.data];
      set(() => ({ fetchRequestsRes: response.data, loading: false }));

      console.log({ myDATATA: get().fetchRequestsRes });
    } catch (err) {
      console.log("im not here ?");

      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  fetch: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/nft`
      );
      // const myDATATA = [...response.data];
      set(() => ({ data: response.data.nfts, loading: false }));

      console.log({ myDATATA: get().data.nfts });
    } catch (err) {
      console.log("im not here ?");

      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  fetchNFTById: async (tokenId, pending) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/nft/nft-id/${tokenId}?pending=${pending}`
      );
      set(() => ({ nftByIdRes: response.data, loading: false }));

      console.log({ myDATATA: get().nftByIdRes });
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  fetchNFTRequestById: async (id) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_IP}/nft/nft-request/${id}`
      );
      set(() => ({ nftByIdRes: response.data, loading: false }));

      console.log({ myDATATA: get().nftByIdRes });
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  createNft: async (formData) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/nft`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      set(() => ({ createNFtRes: response.data, loading: false }));
      console.log({ myDATATA: get().createNFtRes });
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  sellNft: async (tokenId, price) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/nft/sell`,
        { tokenId, price }
      );
      set(() => ({
        nftByIdRes: response.data,
        sellNFTRes: response.data,
        loading: false,
      }));
      console.log({ myDATATA: get().sellNFTRes });
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  buyNFT: async (tokenId, buyerAdr) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/nft/buy`,
        { tokenId, buyerAdr }
      );
      set(() => ({
        nftByIdRes: response.data,
        buyNFTRes: response.data,
        loading: false,
      }));
      console.log({ myDATATA: get().buyNFTRes });
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
  respondNft: async (data) => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/nft/respond-nft`,
        data
      );
      set(() => ({ respondNftRes: response.data, loading: false }));
      console.log({ myDATATA: get().respondNftRes });
    } catch (err) {
      set(() => ({ errors: JSON.stringify({ err }), loading: false }));
    }
  },
}));

export default useNftStoreZustand;
