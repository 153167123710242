import React from "react";

function TextArea({ register, errors, name, title, disabled }) {
  return (
    <div className="flex flex-col">
      <textarea
        className="rtl w-full h-32 flex flex-row justify-between items-center appearance-none relative px-8 py-4 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-black placeholder:font-bold "
        placeholder={title}
        name={name}
        rows={3}
        {...register(name)}
        disabled={disabled}
      />
      {errors[name] && (
        <span className="text-red-500 font-thin italic">
          {errors[name].message}
        </span>
      )}
    </div>
  );
}

export default TextArea;
