import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/store";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Suspense fallback="loading">
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </Suspense>
  </StrictMode>
);
