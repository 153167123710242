import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { nftApi } from "../api/api";
import BreadCrumbs from "../components/shared/BreadCrambs";
import NFTcard from "../components/shared/NFTcard";
import useModalZustand from "../store/useModalZustand";
import Loading from "./Loading";

function StudentCreatedNfts() {
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);

  const [studentNfts, setStudentNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { studentId } = useParams();

  useEffect(() => {
    const getNfts = async () => {
      setIsLoading(true);
      try {
        const { data, status } = await nftApi.getNftsByCreator(studentId);
        if (data && status === 200) setIsLoading(false);

        setStudentNfts(data);
      } catch (error) {
        setIsLoading(false);
        console.log({ error });
        return setErrorMsg(error);
      }
    };

    getNfts();
  }, [studentId, setErrorMsg]);
  return (
    <div>
      <BreadCrumbs />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-row flex-wrap gap-8 m-20">
          {studentNfts.map((nft) => {
            return <NFTcard key={v4()} item={nft} />;
          })}
        </div>
      )}
    </div>
  );
}

export default StudentCreatedNfts;
