import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import userImgPlaceHolder from "../assets/icons/icons8-user-90.svg";
import { BiArrowBack } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";

// import PDF from "../components/Icons/PDF";
import CopyableText from "../components/shared/CopyableText";
import Loading from "./Loading";

import useNftStoreZustand from "../store/useNftStoreZustand";
import GenericButtonBuySell from "../components/shared/GenericButtonBuySell";
import categories from "../constants/categories";

// TODO: replace the buy sell component with btn accept and reject

function NFTDetailsModerator() {
  const {
    respondNft,
    respondNftRes,
    fetchNFTRequestById,
    nftByIdRes,
    loading,
  } = useNftStoreZustand((state) => ({
    fetchNFTRequestById: state.fetchNFTRequestById,
    nftByIdRes: state.nftByIdRes,
    loading: state.loading,
    respondNftRes: state.respondNftRes,
    respondNft: state.respondNft,
  }));
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchNFTRequestById(id, true);
  }, [fetchNFTRequestById, id]);

  useEffect(() => {
    if (Object.keys(nftByIdRes).length !== 0) {
      navigate("/dashboard/requests");
      window.location.reload();
    }
  }, [respondNftRes]);

  const [showRejectInput, setShowRejectInput] = useState(false);
  const [rejectMsg, setRejectMsg] = useState("");

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-screen pt-20 p-8 flex flex-col justify-start items-center bg-[#fff]">
          {nftByIdRes && (
            <div className="w-[90vw] md:w-[750px] lg:w-[970px] xl:w-[1200px] flex flex-row justify-between gap-12 text-right ">
              <img
                crossOrigin="anonymous"
                className="w-1/2 h-[50vh] rounded-xl object-fill object-center "
                src={`${process.env.REACT_APP_SERVER_IP}/${nftByIdRes.image}`}
              />
              <div
                className="w-1/2 flex flex-col gap-8 "
                style={{ direction: "rtl" }}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col  ">
                    <h3 className="text-3xl font-bold">
                      {nftByIdRes.name || "الكسو NFT"}
                      {/* <a
                        href={
                          `https://testnet.ftmscan.com/token/${process.env.REACT_APP_ALECSO_NFT_ADDRESS}?a=${nftByIdRes.tokenId}` ||
                          "tokenId"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="w-[15vw] truncate text-lg"
                        style={{ direction: "ltr" }}
                      >
                        🔗
                      </a> */}
                    </h3>
                  </div>
                  {nftByIdRes?.marketStatus?.status_name === "onSale" && (
                    <img
                      src={require("../assets/images/nft for sale.png")}
                      className="w-[6vw] "
                    />
                  )}
                </div>
                <p className="font-semibold">الصنف: </p>
                <p className="font-thin text-[#7D7D7D] leading-tight">
                  {nftByIdRes.category || categories[0]}
                </p>
                <p className="font-semibold">الوصف: </p>
                <p className="font-thin text-[#7D7D7D] leading-tight">
                  {nftByIdRes.description ||
                    "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبورأنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريدأكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس أيا كوممودو كونسيكيوات . ديواسأيوتي أريري دولار إن ريبريهينديرأيت فوليوبتاتي فيلايت أيسسي كايلليوم دولار أيو فيجايتنيولا باراياتيور. أيكسسيبتيور ساينت أوككايكات كيوبايداتات نون بروايدينت ,سيونت ان كيولباكيو أوفيسيا ديسيريونتموليت انيم أيدي ايست لابوريوم."}
                </p>
                <div className="flex gap-6">
                  <img
                    src={nftByIdRes.owner?.photo || userImgPlaceHolder}
                    alt="avatar"
                    className="w-20 h-20 object-cover rounded-full "
                  />
                  <div className="flex flex-col justify-around items-start ">
                    <p className="text-gray-900 font-thin text-sm ">
                      منشئ الرّمز
                    </p>
                    <p className="text-black font-bold text-lg ">
                      {`${nftByIdRes.owner?.name}` || "أحمد"}
                    </p>
                    <div className="flex flex-row gap-2 text-gray-900 font-thin text-sm ">
                      <CopyableText
                        text={nftByIdRes.owner?.wallet_address || "0x2601..57"}
                      ></CopyableText>
                    </div>
                  </div>
                </div>
                <div className="w-5/6 flex flex-row gap-10">
                  {/* <div className="w-1/2 flex flex-col items-center bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25]">
                    <h6 className="font-bold mb-2">تحقق من ملف PDF</h6>
                    <a
                      href={`${process.env.REACT_APP_SERVER_IP}/${nftByIdRes.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PDF />
                    </a>
                  </div> */}
                  <div className="w-1/3 flex flex-col items-center bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25]">
                    <h6 className="font-bold mb-2">رابط المشروع</h6>
                    <a
                      href={`${nftByIdRes.projectLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full text-sm truncate items-center"
                      style={{ direction: "ltr" }}
                    >
                      <FiExternalLink className="inline mr-2" />
                      {nftByIdRes.projectLink}
                    </a>
                  </div>
                </div>
                <div className="w-5/6 flex flex-row  gap-4">
                  {!showRejectInput ? (
                    <div className="flex flex-row gap-4">
                      <GenericButtonBuySell
                        onClick={() => {
                          respondNft({
                            _id: nftByIdRes._id,
                            response: "approved",
                          });
                        }}
                        btnTitle="قبول إنشاء الرمز"
                      />

                      <GenericButtonBuySell
                        onClick={() => {
                          // respondNftRes({
                          //   _id: nftByIdRes._id,
                          //   response: "rejected",
                          //   rejectionMsg: "reason",
                          // });
                          setShowRejectInput(true);
                        }}
                        btnTitle="رفض إنشاء الرمز"
                      />
                    </div>
                  ) : (
                    <div className="w-full">
                      <textarea
                        className="w-full h-full p-4 shadow-2xl shadow-[#6345ed]/[0.25]"
                        rows={3}
                        value={rejectMsg}
                        onChange={(e) => {
                          setRejectMsg(e.target.value);
                        }}
                      />
                      <div className="flex flex-row gap-4">
                        <GenericButtonBuySell
                          onClick={() => {
                            setShowRejectInput(false);
                          }}
                          btnTitle="رجوع"
                          icon={<BiArrowBack className="text-white " />}
                        />
                        <GenericButtonBuySell
                          onClick={() => {
                            respondNft({
                              _id: nftByIdRes._id,
                              response: "rejected",
                              rejectionMsg: rejectMsg,
                            });
                          }}
                          btnTitle="رفض إنشاء الرمز"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default NFTDetailsModerator;
