import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useModalZustand = create(
  devtools((set) => ({
    errorMsg: "",
    successMsg: "",
    setErrorMsg: (msg) => {
      console.log({
        msg: msg,
        msgMessage: msg.message,
        msgJSON: JSON.stringify(msg),
      });
      let myMsg = JSON.stringify(msg.message);
      if (typeof msg?.message === String) myMsg = msg.message;
      if (msg.message === undefined) myMsg = msg;
      if (msg === "") myMsg = "";
      set({ errorMsg: myMsg });
      console.log({ myMsg });
    },
    setSuccessMsg: (msg) => {
      set({ successMsg: msg });
    },
  }))
);

export default useModalZustand;
