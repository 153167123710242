/* eslint-disable no-await-in-loop */
import Web3 from "web3";
import alecsoMarketPlaceContract from "./alecsoMarketPlaceABI.js";
import alecsoNftContract from "./alecsonft721ABI.js";
import alecsoCoinContract from "./alecsocoinABI.js";

const web3 = new Web3(window.ethereum);

const marketplaceContract = new web3.eth.Contract(
  alecsoMarketPlaceContract.Abi,
  process.env.REACT_APP_ALECSO_MARKETPLACE_ADDRESS
);
const coinContract = new web3.eth.Contract(
  alecsoCoinContract.Abi,
  process.env.REACT_APP_ALECSO_COIN_ADDRESS
);
const nftContract = new web3.eth.Contract(
  alecsoNftContract.Abi,
  process.env.REACT_APP_ALECSO_NFT_ADDRESS
);
// const adminWallet = process.env.ALECSO_COIN_ADMIN_WALLET;
// const adminPk = process.env.ALECSO_COIN_ADMIN_PK;

export const buyNFTMarketplace = async (
  tokenId,
  priceNFT,
  sellerAdr,
  buyerAdr
) => {
  try {
    const amount = web3.utils.toBN(Math.trunc(priceNFT * 1000000000000000000));
    console.log("🚀 buyNFT ~ priceNFT", priceNFT, typeof priceNFT);
    console.log("🚀 buyNFT ~ amount", amount, typeof amount);

    // 3
    const soldeBuyer = await coinContract.methods.balanceOf(buyerAdr).call();
    console.log("🚀 buyNFT ~ soldeBuyer", soldeBuyer);
    if (parseInt(soldeBuyer) < parseInt(amount)) {
      throw Error("رصيد غير كاف");
    }
    // 4

    // Approve marketplace contract to spend from buyer alecso coins
    const resApproveCoin = await coinContract.methods
      .approve(process.env.REACT_APP_ALECSO_MARKETPLACE_ADDRESS, soldeBuyer)
      .send({ from: buyerAdr });

    // 4 buy
    const resBuy = await marketplaceContract.methods
      .buyNFT(sellerAdr, tokenId, amount)
      .send({ from: buyerAdr, gas: 5000000 })
      .on("error", (error) => {
        throw new Error(error?.message);
      });

    console.log("🚀 buyNFT ~ soldeBuyer", soldeBuyer);
    return { data: "success buy NFT", resApproveCoin, resBuy };
  } catch (error) {
    console.log("🚀 buyNFT ~ error", error);
    throw new Error(error?.message);
  }
};

export const sellNFTMarketplace = async (tokenId, from) => {
  try {
    const permission = await getPermissionsOfNFT(tokenId);
    console.log("🚀 sellNFTMarketplace ~ permission", permission);
    if (permission?.res !== "0x0000000000000000000000000000000000000000") {
      return;
    }
    console.log("i am selling from address", from, "tokenId:", tokenId);
    const res = await nftContract.methods
      .approve(process.env.REACT_APP_ALECSO_MARKETPLACE_ADDRESS, tokenId)
      .send({ from })
      .on("error", (error) => {
        console.log({ error });
        throw new Error(error?.message);
      });

    return { data: "success sell NFT", res };
  } catch (error) {
    console.log("🚀 sellNFT ~ error", error);

    throw new Error(error?.message);
  }
};

export const getOwnerOfToken = async (tokenId) => {
  try {
    console.log("tokenId:", tokenId);

    const res = await nftContract.methods.ownerOf(tokenId).call();

    console.log("i got owner of:", res);

    return { data: "success calling owner", res };
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const getPermissionsOfNFT = async (tokenId) => {
  try {
    console.log("tokenId:", tokenId);

    const res = await nftContract.methods.getApproved(tokenId).call();

    console.log("is approved ? :", res);

    return { data: "success calling getApproved", res };
  } catch (error) {
    throw new Error(error?.message);
  }
};

// "This contract object doesn't have address set yet, please set an address first."

// "invalid address
// (argument="address", value=undefined, code=INVALID_ARGUMENT, version=address/5.7.0)
// (argument="to", value=undefined, code=INVALID_ARGUMENT, version=abi/5.7.0)"
