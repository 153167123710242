import React, { useEffect, useState } from "react";

// TODO use it in layout page
function BtnTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 900) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    showTopBtn && (
      <button
        type="button"
        className="fixed bottom-6 right-4 rounded-full bg-fuchsia-500 w-12 h-12  cursor-pointer flex items-center justify-center z-20"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <span className="absolute rotate-90 text-white font-black text-lg mb-2 mr-2">
          &#x3008;
        </span>
      </button>
    )
  );
}

export default BtnTop;
