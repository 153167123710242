import React from "react";
import { FaCopy } from "react-icons/fa";

function CopyableText({ text }) {
  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <div className="flex flex-row gap-2 items-center">
      <button onClick={copyContent}>
        <FaCopy />
      </button>
      <p className="w-[15vw] truncate" style={{ direction: "ltr" }}>
        {text}
      </p>
    </div>
  );
}

export default CopyableText;
