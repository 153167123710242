import AddNFT from "./pages/AddNFT";
// import Home from "./pages/Home";
import React, { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";

import Layout from "./layouts/Layout";
import NftsPage from "./pages/NftsPage";
import StudentNFTs from "./pages/StudentNFTs";
import Faucet from "./pages/Faucet";
import NFTDetails from "./pages/NFTDetails";
import StudentCreatedNfts from "./pages/StudentCreatedNfts";
import StudentsList from "./pages/StudentsList";
import Login from "./pages/Login";
import AddModerator from "./pages/AddModerator";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

const LandingPage = lazy(() => import("./pages/LandingPage"));

import SideBar from "./components/Sidebar";
import sidebar_menu from "./constants/sidebar-menu";
import sidebar_menu_admin from "./constants/sidebar-menu_admin";

import "./AppDashboard.css";
import NftRequests from "./pages/NftRequests";
import ReportedNfts from "./pages/Reported-Nfts";
import NFTDetailsModerator from "./pages/NFTDetailsModerator";
import NftReports from "./pages/Nfts-Reports/NftReports";
import LoginModerator from "./pages/LoginModerator";
import ForgotPasswordModerator from "./pages/ForgotPasswordModerator";
import ResetPasswordModerator from "./pages/ResetPasswordModerator";
import ChangePasswordModerator from "./pages/ChangePasswordModerator";
import CreateOrChange from "./pages/CreateOrChange";
import ErrorModal from "./coreUI/ErrorModal";
import SuccessModal from "./coreUI/SuccessModal";
import NftListModerator from "./pages/NftListModerator";
import Moderators from "./pages/Moderators";
import EditModerator from "./pages/EditModerator";
import { ErrorBoundary } from "react-error-boundary";
import ErrorRoute from "./coreUI/ErrorRoute";
import ErrorFallback from "./coreUI/ErrorFallback";
import FaucetOld from "./pages/FaucetOld";

const routers = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorRoute />}>
      {/* Layout of normal app */}
      <Route
        path=""
        element={
          <ErrorBoundary fallback={<ErrorFallback />}>
            <Layout />
          </ErrorBoundary>
        }
        handle={{ crumb: { name: "الرئيسية", link: "/" } }}
      >
        <Route index element={<LandingPage />} />
        <Route
          path="add-nft"
          element={<AddNFT />}
          handle={{ crumb: { name: "أضف رمز NFT جديد", link: "/add-nft" } }}
        />
        <Route
          path="nft-detail/:tokenId"
          element={<NFTDetails moderator={false} />}
          handle={{ crumb: { name: "تفاصيل NFT", link: "/nft-detail" } }}
        />

        <Route
          path="nfts"
          element={<NftsPage />}
          handle={{ crumb: { name: "عرض الرموز", link: "/explore-1" } }}
        />
        <Route
          path="student-nfts"
          element={<StudentNFTs />}
          handle={{ crumb: { name: "رموزك", link: "/student-nfts" } }}
        />
        <Route
          path="faucet"
          element={<Faucet />}
          handle={{
            crumb: {
              name: "تسجيل مستخدم جديد",
              link: "/faucet",
            },
          }}
        />

        <Route
          path="faucet-old"
          element={<FaucetOld />}
          handle={{
            crumb: {
              name: "المشاركة عبر الأسبوع العربي للبرمجة",
              link: "/faucet-old",
            },
          }}
        />
        <Route
          path="student-creations/:studentId"
          element={<StudentCreatedNfts />}
          handle={{
            crumb: {
              name: "مساهمات أخرى",
              link: "/student-creations/:studentId",
            },
          }}
        />
        <Route
          path="students"
          element={<StudentsList />}
          handle={{
            crumb: {
              name: "كل الطلاب",
              link: "/students",
            },
          }}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* auth moderator */}
        <Route path="/login-moderator" element={<LoginModerator />} />
        <Route
          path="/forgot-password-moderator"
          element={<ForgotPasswordModerator />}
        />
        <Route
          path="/reset-password-moderator"
          element={<ResetPasswordModerator />}
        />
      </Route>

      {/* Layout of dashboard moderator app */}
      <Route
        path="dashboard"
        element={
          <>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <div className="dashboard-container">
                <SideBar menu={sidebar_menu} />
                <div className="dashboard-body">
                  <Outlet />
                </div>
                <ErrorModal />
                <SuccessModal />
              </div>
            </ErrorBoundary>
          </>
        }
        handle={{ crumb: { name: "لوحة القيادة", link: "/dashboard" } }}
      >
        <Route path="" element={<div></div>} />

        <Route path="nftsModerator" element={<NftListModerator />} />
        <Route
          path="nftsModerator/:tokenId"
          element={<NFTDetails moderator={true} />}
        />

        <Route path="requests" element={<NftRequests />} />
        <Route path="requests/:id" element={<NFTDetailsModerator />} />

        <Route path="reports" element={<ReportedNfts />} />
        <Route path="reports/:tokenId" element={<NftReports />} />

        {/* <Route path="profile" element={<Orders />} /> */}
        <Route path="profile" element={<ChangePasswordModerator />} />
      </Route>

      {/* Layout of dashboard admin app */}
      <Route
        path="dashboard-admin"
        element={
          <>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <div className="dashboard-container">
                <SideBar menu={sidebar_menu_admin} />
                <div className="dashboard-body">
                  <Outlet />
                </div>
                <ErrorModal />
                <SuccessModal />
              </div>
            </ErrorBoundary>
          </>
        }
      >
        <Route path="moderators" element={<Moderators />} />

        <Route path="add-moderator" element={<AddModerator />} />
        <Route path="edit-moderator/:moderatorId" element={<EditModerator />} />

        <Route path="change-password" element={<ChangePassword />} />
        <Route path="create-change" element={<CreateOrChange />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={routers} />;
}

export default App;
