import React from "react";
import BtnTop from "../components/BtnTop";

import CategorySelect from "../components/NftCollection/CategorySelect";
import BreadCrumbs from "../components/shared/BreadCrambs";

function NftsPage() {
  return (
    <div>
      <BtnTop />
      <BreadCrumbs />
      <CategorySelect />
    </div>
  );
}

export default NftsPage;
