import React from "react";

function DateInput({ type, register, errors, name, title, disabled }) {
  return (
    <div className="w-full flex gap-2 items-center justify-between">
      <div className="flex flex-col w-1/2">
        <input
          className="h-16 flex flex-row justify-between items-center relative bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right "
          placeholder={title}
          type={type}
          name={name}
          {...register(name)}
          disabled={disabled}
        />
        {errors[name] && (
          <span className="text-red-500 font-thin italic">
            {errors[name].message}
          </span>
        )}
      </div>
      <label className="pr-8 w-1/2 text-right font-bold">{title}</label>
    </div>
  );
}

export default DateInput;
