import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useNftStoreZustand from "../store/useNftStoreZustand";
import useStudentStoreZus from "../store/useStudentStoreZus";
import {
  buyNFTMarketplace,
  sellNFTMarketplace,
} from "../utils/blockchain/marketplaceSCMethods";
import GenericButtonBuySell from "./shared/GenericButtonBuySell";
import useModalZustand from "../store/useModalZustand";

function BuySellComp() {
  // eslint-disable-next-line no-unused-vars
  const { nftByIdRes, sellNFTRes, buyNFTRes, sellNft, buyNFT } =
    useNftStoreZustand((state) => ({
      nftByIdRes: state.nftByIdRes,
      sellNFTRes: state.sellNFTRes,
      buyNFTRes: state.buyNFTRes,
      sellNft: state.sellNft,
      buyNFT: state.buyNFT,
    }));
  const { setErrorMsg, setSuccessMsg } = useModalZustand((state) => ({
    setErrorMsg: state.setErrorMsg,
    setSuccessMsg: state.setSuccessMsg,
  }));

  const loginStudentRes = useStudentStoreZus((state) => state.loginStudentRes);
  const [hidden, setHidden] = useState(false);
  const [isCurrentOwner, setIsCurrentOwner] = useState(false);
  const [isUnderSale, setIsUnderSale] = useState(false);

  useEffect(() => {
    console.log({ sellNFTRes, nftByIdRes, buyNFTRes });

    // is the current student wallet_address equals the wallet_address of the owner of the nft
    setIsCurrentOwner(
      nftByIdRes?.owner?.wallet_address === loginStudentRes?.wallet_address
    );
    setIsUnderSale(
      nftByIdRes?.price !== undefined && nftByIdRes?.price !== null
    );
    console.log({ loginStudentRes });
    setHidden(Object.keys(loginStudentRes).length === 0);
  }, [loginStudentRes, nftByIdRes]);

  const nftSchema = yup.object({
    price: yup
      .number()
      .required("Price is required")
      .positive("Price must be a positive number"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(nftSchema),
  });

  const onSubmit = async (values) => {
    try {
      console.log({ values });
      await sellNFTMarketplace(
        nftByIdRes?.tokenId,
        loginStudentRes?.wallet_address
      );
      await sellNft(nftByIdRes?.tokenId, values.price);
      setSuccessMsg("نجاح بيع الرّمز");
      reset();
    } catch (error) {
      console.log({ error });
      return setErrorMsg(error);
    }
  };

  const buyThisNft = async () => {
    try {
      console.log({
        params: {
          tokenId: nftByIdRes?.tokenId,
          priceNFT: nftByIdRes?.price,
          sellerAdr: nftByIdRes?.owner?.wallet_address,
          buyerAdr: loginStudentRes?.wallet_address,
        },
      });
      await buyNFTMarketplace(
        nftByIdRes?.tokenId,
        nftByIdRes?.price,
        nftByIdRes?.owner?.wallet_address,
        loginStudentRes?.wallet_address
      );

      await buyNFT(nftByIdRes?.tokenId, loginStudentRes?.wallet_address);
    } catch (error) {
      console.log({ error });
      return setErrorMsg(error);
    }
  };

  return (
    <div>
      {hidden ? (
        <p
          className="text-lg text-red-500 font-Tajawal font-thin mt-5"
          style={{ direction: "rtl" }}
        >
          عليك أن تتصل بعنوان Metamask صحيح وذلك لتتمكن من الاستفادة من خدمات
          التطبيق بشكل كامل.
        </p>
      ) : (
        <>
          {isCurrentOwner ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-5/6 flex gap-2 bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25] ">
                <div className="w-1/2 flex flex-col">
                  <h6 className="font-bold mb-2">
                    {nftByIdRes?.marketStatus?.status_name === "onSale"
                      ? "تغيير"
                      : "أدخل"}{" "}
                    سعر الرّمز
                  </h6>
                  <input
                    type="number"
                    min="0.000"
                    step="0.001"
                    max="1.00"
                    placeholder="price..."
                    className="font-thin"
                    {...register("price")}
                  />
                  {errors.price && (
                    <span className="text-red-500 font-thin italic">
                      {errors.price.message}
                    </span>
                  )}
                </div>
                {isUnderSale && (
                  <div>
                    <h6 className="font-bold mb-2">السعر الحالي</h6>
                    <p className="font-thin">{`${
                      nftByIdRes?.price || 25
                    } ALSCC`}</p>
                  </div>
                )}
              </div>
              <div className="w-1/2">
                <GenericButtonBuySell
                  type="submit"
                  btnTitle={`${
                    nftByIdRes?.marketStatus?.status_name === "onSale"
                      ? "تغيير سعر"
                      : "بيع"
                  } الرّمز`}
                />
              </div>
            </form>
          ) : (
            <>
              {isUnderSale && (
                <>
                  <div className="w-5/6 bg-white rounded-lg p-4 shadow-2xl shadow-[#6345ed]/[0.25] ">
                    <h6 className="font-bold mb-2">السعر الحالي</h6>
                    <p className="font-thin">{`${
                      nftByIdRes?.price || 25
                    } ALSCC`}</p>
                  </div>
                  <div className="w-1/2">
                    <GenericButtonBuySell
                      onClick={buyThisNft}
                      btnTitle="شراء الرّمز"
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default BuySellComp;
