import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/img-collection/collection-7.jpg";
import userImgPlaceHolder from "../../assets/icons/icons8-user-90.svg";
import GenericButton from "../shared/GenericButton";

function BestSellerCard({ contributor }) {
  const [showCardBottom, setShowCardBottom] = useState(false);
  const navigate = useNavigate();
  console.log({ contributor });

  const displayCardBottom = () => {
    setShowCardBottom(true);
  };
  const hideCardBottom = () => {
    setShowCardBottom(false);
  };
  return (
    <div
      className="relative w-full"
      onMouseEnter={displayCardBottom}
      onMouseLeave={hideCardBottom}
    >
      <div className="relative flex flex-col rounded-lg overflow-hidden bg-bg-2 text-center border box-border border-gray-300">
        <div className="w-full object-cover overflow-hidden">
          <img
            src={image}
            alt="background"
            className={`h-full object-cover w-full ${
              showCardBottom ? "scale-125" : ""
            }`}
          />
        </div>
        <div className="relative w-full overflow-visible border-solid -mt-12 ">
          <img
            src={contributor?.creator[0]?.photo || userImgPlaceHolder}
            alt="avatar"
            className={`w-24 h-24 rounded-full border-2 border-solid  mx-auto border-violet-600 p-2 ${
              showCardBottom
                ? "transition  delay-100 border-opacity-100"
                : "border-opacity-0"
            }`}
          />
        </div>
        {/* card content */}
        <div className="relative mx-0 mt-4 text-center">
          <h5 className="font-bold text-lg">
            <a href="author.html">{contributor?.creator[0]?.name}</a>{" "}
          </h5>
          <div className="text-gray-500 mb-8">
            {contributor?.nftsCount} مساهمة
          </div>
          {/* card bottom */}
          <div
            className={`mb-6  ${
              !showCardBottom
                ? "h-0 opacity-0 overflow-hidden"
                : "h-30  transition ease-in-out opacity-100 duration-700  "
            }`}
          >
            <div className="w-full flex justify-center items-center ">
              <GenericButton
                title="اطلاع"
                btnStyle="bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                textStyle="px-7 py-2.5 text-sm"
                isIcon={false}
                onClick={() =>
                  navigate(`/student-creations/${contributor._id}`)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestSellerCard;
