import React from "react";
// import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

function CardFooter({ item }) {
  const { description, imageSrc } = item;
  return (
    <div className="flex flex-col justify-center items-center max-w-[300px] p-5 m-5">
      <img src={imageSrc} alt="logo" width={150} height={150} />
      <p
        className="my-5 text-sm text-right"
        style={{
          direction: "rtl",
        }}
      >
        {description}
      </p>
      {/* <div className="flex space-x-2">
        <Link to={links.youtube}>
          <img src="instagram.svg" alt="youtube" />
        </Link>
        <Link to={links.linkedin}>
          <img src="linkedin.svg" alt="linkedin" />
        </Link>
        <Link to={links.twitter}>
          <img src="twitter.svg" alt="twitter" />
        </Link>
        <Link to={links.facebook}>
          <img src="facebook.svg" alt="fb" />
        </Link>
      </div> */}
    </div>
  );
}

CardFooter.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    imageSrc: PropTypes.string,
    // links: PropTypes.shape({
    //   facebook: PropTypes.string,
    //   youtube: PropTypes.string,
    //   linkedin: PropTypes.string,
    //   twitter: PropTypes.string,
    // }),
  }).isRequired,
};

export default CardFooter;
