import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  reset,
  createModerator,
} from "../store/features/moderator/moderatorSlice";

import { toast } from "react-toastify";

import GenericButton from "../components/shared/GenericButton";
import Loading from "./Loading";

function AddModerator() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moderators, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.moderator
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
      console.log({ message });
    }

    if (isSuccess && moderators) {
      console.log(moderators);
      navigate("../moderators");
    }

    dispatch(reset());
  }, [moderators, isError, isSuccess, message, navigate, dispatch]);

  // Restricted access
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    university: "",
    email: "",
  });
  const { firstName, lastName, university, email } = formData;

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(true);
    dispatch(createModerator(formData));
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="h-[80vh] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12">
      <div className="flex justify-center">
        <form
          className="w-full lg:w-1/3 bg-white p-6 rounded-lg"
          // onSubmit={handleSubmit}
        >
          <h1 className="font-Tajawal font-bold text-5xl text-right antialiased pb-10">
            أضف المشرف
          </h1>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل الاسم الأول
            </label>
            <input
              name="firstName"
              value={firstName}
              onChange={handleChange}
              placeholder="... الاسم الأول"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold 
               ${errors.idError ? "border-red-600" : ""}`}
            />
          </div>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل اسم العائلة
            </label>
            <input
              name="lastName"
              value={lastName}
              onChange={handleChange}
              placeholder="... اسم العائلة"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold 
               ${errors.idError ? "border-red-600" : ""}`}
            />
          </div>
          <div className="mb-4 w-full">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل اسم الجامعة
            </label>
            <input
              name="university"
              value={university}
              onChange={handleChange}
              placeholder="... اسم الجامعة"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold 
               ${errors.idError ? "border-red-600" : ""}`}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium text-xl text-right mb-2">
              أدخل البريد الإلكتروني
            </label>
            <input
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="... البريد الإلكتروني"
              className={`w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold ${
                errors.walletError ? "border-red-600" : ""
              }`}
            />
          </div>
          <div className="flex justify-between">
            <GenericButton
              title="أضف المشرف"
              btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
              textStyle="px-6"
              isIcon={false}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
export default AddModerator;
