import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { studentApi } from "../api/api";
import BestSellerCard from "../components/BestSeller/BestSellerCard";
import BtnTop from "../components/BtnTop";
import BreadCrumbs from "../components/shared/BreadCrambs";
import GenericButton from "../components/shared/GenericButton";
import useModalZustand from "../store/useModalZustand";
import Loading from "./Loading";

function StudentsList() {
  const setErrorMsg = useModalZustand((state) => state.setErrorMsg);

  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getStudents = async () => {
      setIsLoading(true);
      try {
        const { data, status } = await studentApi.getBestContributors();
        if (data && status === 200) setIsLoading(false);

        setStudents(data);
      } catch (error) {
        setIsLoading(false);
        console.log({ error });
        return setErrorMsg(error);
      }
    };
    getStudents();
  }, [setErrorMsg]);
  return (
    <div>
      <BtnTop />
      <BreadCrumbs />
      <section className="py-[100px] bg-gray-100 block">
        {/* Container */}
        <div className="max-w-full px-4 lg:mx-10 2xl:mx-60 ">
          {/* List of cards */}
          {isLoading ? (
            <Loading />
          ) : (
            <div className="grid  md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  gap-8 ">
              {students.map((element) => {
                return <BestSellerCard key={v4()} contributor={element} />;
              })}
            </div>
          )}
          {/* Button */}
          <div className="flex justify-center">
            <GenericButton
              title="عرض المزيد"
              btnStyle="w-[200px] h-[55px] mt-[40px] bg-black hover:bg-violet-600"
              textStyle="pt-5"
              isIcon={false}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default StudentsList;
