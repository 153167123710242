import React from "react";
import PropTypes from "prop-types";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import walletIcon from "../../assets/images/icon/connect-wallet.svg";

function GenericButton({
  title,
  btnStyle = "",
  textStyle,
  isIcon,
  children,
  onClick,
  disabled,
  type,
}) {
  if (type === "submit") {
    return (
      <button
        type={type}
        className={`
       rounded-full hover:cursor-pointer flex justify-center ${btnStyle}`}
      >
        <>
          {disabled && (
            <AiOutlineLoading3Quarters className="text-white animate-spin" />
          )}
          <span className={`text-white font-bold ${textStyle}`}>{title}</span>
        </>
      </button>
    );
  }
  return (
    <div
      onClick={onClick}
      className={`
       rounded-full hover:cursor-pointer flex justify-center ${btnStyle}`}
    >
      {isIcon ? (
        <button
          disabled={disabled}
          className="flex justify-around items-center xl:px-6"
        >
          <span className={`text-white font-bold truncate ${textStyle}`}>
            {title}
          </span>
          {children}
        </button>
      ) : (
        <>
          {disabled && (
            <AiOutlineLoading3Quarters className="text-white animate-spin" />
          )}
          <span className={`text-white font-bold ${textStyle}`}>{title}</span>
        </>
      )}
    </div>
  );
}

GenericButton.propTypes = {
  title: PropTypes.string.isRequired,
  btnStyle: PropTypes.string.isRequired,
  textStyle: PropTypes.string.isRequired,
  isIcon: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GenericButton;
