import React, { useState, useEffect } from "react";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import "./styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { nftApi } from "../../api/api";
import { useNavigate } from "react-router-dom";

function ReportedNfts() {
  const [search, setSearch] = useState("");
  const [nfts, setNfts] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getReportedNftsList = async () => {
      const { data } = await nftApi.getReportedNfts();
      setNfts(data);
      setPagination(calculateRange(data, 5));
      setNfts(sliceData(data, page, 5));
    };
    getReportedNftsList();
  }, [page]);

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = nfts.filter(
        (item) =>
          item.creator.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.reportStatus.toLowerCase().includes(search.toLowerCase())
      );
      setPagination(calculateRange(search_results, 5));
      setNfts(sliceData(search_results, page, 5));
    } else {
      setPagination(calculateRange(nfts, 5));
      setNfts(sliceData(nfts, page, 5));
    }
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
  };

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>قائمة التقارير</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="بحث.."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>معرف الرمز</th>
            <th>اسم الرمز</th>
            <th>اسم الطالب</th>
            <th>وضع تقرير</th>
          </thead>

          {nfts.length !== 0 ? (
            <tbody>
              {nfts?.map((nft, index) => (
                <tr
                  key={index}
                  className="cursor-pointer hover:bg-slate-300"
                  onClick={() => navigate(`/dashboard/reports/${nft.tokenId}`)}
                >
                  <td>
                    <span>{nft?.tokenId}</span>
                  </td>
                  <td>
                    <span>{nft?.name}</span>
                  </td>

                  <td>
                    <div className="flex justify-end">
                      <span>
                        {nft?.creator?.firstName} {nft?.creator?.lastName}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-end">
                      {nft.reportStatus === "approved" ? (
                        <img
                          src={DoneIcon}
                          alt="paid-icon"
                          className="dashboard-content-icon"
                        />
                      ) : nft.reportStatus === "rejected" ? (
                        <img
                          src={CancelIcon}
                          alt="canceled-icon"
                          className="dashboard-content-icon"
                        />
                      ) : nft.reportStatus === "pending" ? (
                        <img
                          src={RefundedIcon}
                          alt="refunded-icon"
                          className="dashboard-content-icon"
                        />
                      ) : null}
                      <span
                        className={`${
                          nft?.reportStatus === "accepted"
                            ? "text-green-600"
                            : nft?.reportStatus === "pending"
                            ? "text-orange-500"
                            : nft?.reportStatus === "rejected"
                            ? "text-red-600"
                            : ""
                        }`}
                      >
                        {nft?.reportStatus}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {nfts.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">لايوجد بيانات</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportedNfts;
