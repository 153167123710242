import React, { useState, useEffect } from "react";
import axios from "axios";

import GenericButton from "../components/shared/GenericButton";

function ForgotPasswordModerator() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  // isSubmitted pour que la validation d email devient en temps reel si j'ai deja cliqué dur button valider
  const [isSubmitted, setIsSubmitted] = useState(false);

  // submitted state to fire api call
  const [submitted, setSubmitted] = useState(false);

  // state to show msg that email was sent
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const callApi = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_IP}/auth-moderator/forgotPassword`,
          { email }
        );
        if (res.status === 200) {
          setSent(true);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: ForgotPassword.jsx:25 ~ callApi ~ error:",
          error
        );
      }
    };
    if (submitted) {
      callApi();
    }
  }, [submitted]);

  const validate = (val) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let validity = true;
    if (!val) {
      setEmailError("البريد الالكتروني مطلوب!");
      validity = false;
    } else if (!regex.test(val)) {
      setEmailError("هذا ليس تنسيق بريد إلكتروني صالح!");
      validity = false;
    }

    if (validity) {
      setEmailError("");
    }

    return validity;
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const validity = validate(email);

    if (validity) {
      setSubmitted(true);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    validate(value);
    setEmail(value);
  };

  return (
    <>
      <div className="h-[70vh] bg-cover bg-no-repeat bg-[#6345ed]/[0.07] p-12">
        <div className="flex justify-center">
          {sent ? (
            <div className="w-full lg:w-1/3 bg-white p-6 rounded-lg text-right text-2xl">
              <h1> تحقق من بريدك الالكتروني</h1>
            </div>
          ) : (
            <form className="w-full lg:w-1/3 bg-white p-6 rounded-lg">
              <h1 className="font-Tajawal font-bold text-5xl text-right antialiased pb-10">
                نسيت كلمة المرور
              </h1>

              <div className="mb-4 w-full">
                <label className="block text-gray-700 font-medium text-xl text-right mb-2">
                  البريد الإلكتروني
                </label>

                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  required
                  className="w-full h-16 flex flex-row justify-between items-center appearance-none relative px-8 bg-[#6345ed]/[0.07] border border-[#6345ed]/[0.12] rounded-md text-right placeholder:text-gray-400 placeholder:font-bold"
                />
                {emailError && isSubmitted && (
                  <p className="text-red-800 text-right py-5">{emailError}</p>
                )}
              </div>

              <div className="flex justify-center">
                <GenericButton
                  title="تأكيد"
                  btnStyle="p-4 bg-gradient-to-r from-fuchsia-500 to-[#6345ED] hover:from-[#6345ED] hover:to-[#6345ED]"
                  textStyle="px-6"
                  isIcon={false}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordModerator;
